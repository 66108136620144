<template><div>
	<div style="text-align:right; font-size:12px;"><i>This page was last updated {{updated_at}}</i></div>
	<h1 class="mb-3">
		<v-icon large class="mr-2" style="margin-left:-20px; margin-top:-5px;" color="blue">{{tab_icon}}</v-icon><span v-html="page_data.page_title"></span>
		<span v-if="!page_data.tab_enabled" style="font-size:18px;" class="red--text ml-2">DISABLED</span>
		<v-btn v-if="is_admin" class="float-right" style="margin-top:6px;" icon color="primary" @click="edit_page"><v-icon>fas fa-edit</v-icon></v-btn>
	</h1>
	<div class="k-page-description fr-view" v-html="page_data.page_description"></div>

	<v-dialog v-if="editor_showing" v-model="editor_showing" persistent max-width="900px">
		<v-card>
			<v-card-title>Page Settings</v-card-title>
			<v-card-text>
				<div class="">
					<v-checkbox class="mt-0 pt-0" label="Tab enabled" v-model="edited_tab_enabled" hide-details></v-checkbox>
				</div>
				<div class="mt-3">
					<v-text-field dense outlined hide-details label="Tab title" v-model="edited_tab_title" placeholder="" autocomplete="new-password"></v-text-field>
				</div>
				<div class="mt-3">
					<v-text-field dense outlined hide-details label="Page title" v-model="edited_page_title" placeholder="" autocomplete="new-password"></v-text-field>
				</div>
				<div class="mt-3">
					<div class="mb-1"><b>Page description:</b></div>
					<froala-wrapper :config="editor_config()" :parent_component="this" v-model="edited_page_description" />
				</div>
			</v-card-text>
			<v-card-actions class="px-4 pb-4">
				<v-btn color="secondary" @click="close_editor">Cancel</v-btn>
				<v-spacer/>
				<v-btn color="primary" @click="save_changes">Save and Close</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>

	<ResourceEditor v-if="resource_being_edited"
		:original_resource="resource_being_edited"
		@edit_resource_cancel="create_resource_cancel"
		@edit_resource_saved="edit_resource_saved"
	/>

</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import ResourceMixin from '@/components/mixins/ResourceMixin'
import ResourceEditor from '@/components/ResourceEditor'

export default {
	mixins: [ResourceMixin],
	components: { ResourceEditor },
	props: {
		tab_id: { type: String, required: true },
		// page_data: { type: Object, required: true },
		// tab_icon: { type: String, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		editor_showing: false,
		edited_tab_title: '',
		edited_page_title: '',
		edited_page_description: '',
		edited_tab_enabled: '',
		original_json: '',

		editor_component_id: U.new_uuid(),
	}},
	computed: {
		...mapState([]),
		...mapGetters(['is_admin']),
		page_data() {
			if (!this.$store.state.page_data[this.tab_id]) return {}
			return this.$store.state.page_data[this.tab_id]
		},
		tab_icon() { return 'fas ' + this.page_data.icon },
		tab_title() { return this.page_data.tab_title },
		page_title() { return this.page_data.page_title },
		page_description() { return this.page_data.page_description },
		tab_enabled() { return this.page_data.tab_enabled },
		updated_at() {
			if (!this.page_data.updated_at) return '-'
			let d = new Date(date.parse(this.page_data.updated_at, 'YYYY-MM-DD HH:mm:ss').getTime() - this.$store.state.time_zone_correction)
			return sr('$1 at $2', date.format(d, 'M/D/YYYY'), date.format(d, 'h:mm A'))
		},
	},
	watch: {
	},
	created() {
	},
	mounted() {
	},
	methods: {
		editor_config(text, inline) {
			if (empty(text)) text = ''
			let config = U.get_froala_config({
				placeholderText: text,
				// initOnClick: true,
				toolbarInline: (inline === true),
				editorClass: 'k-page-description',
			})

			// add the insert resource btn at the front of the moreRich buttons
			config.toolbarButtons.moreRich.buttons.unshift('insert_resource')

			// add the important btn at the front of the moreRich buttons
			config.toolbarButtons.moreRich.buttons.unshift('important')
			return config
		},

		edit_page() {
			this.original_json = JSON.stringify($.extend(true, {}, this.page_data))

			this.edited_tab_title = this.tab_title
			this.edited_page_title = this.page_title
			this.edited_page_description = this.page_description
			this.edited_tab_enabled = this.tab_enabled

			// have to do this for the resource editor to work properly
			this.$store.commit('set', ['editor_components', this.editor_component_id, this])

			this.editor_showing = true
		},

		close_editor() {
			this.editor_showing = false
		},

		save_changes() {
			let updated_json = $.extend(true, {}, this.page_data)
			updated_json.tab_title = this.edited_tab_title
			updated_json.page_title = this.edited_page_title
			updated_json.page_description = window.trim_froala_text(this.edited_page_description)
			updated_json.tab_enabled = this.edited_tab_enabled

			if (JSON.stringify(updated_json) == this.original_json) {
				this.$inform('No changes were made.')
				this.close_editor()
				return
			}

			// console.log(updated_json)

			this.$store.dispatch('save_page', {page_data: updated_json})

			this.close_editor()
		}
	}
}
</script>

<style lang="scss">
.k-page-description {

}
</style>
