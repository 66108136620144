<template><div>
	<div class="py-4 d-flex">
		<v-text-field
			v-model="search"
			prepend-inner-icon="fa fa-search" clearable clear-icon="fa fa-times-circle"
			label="Search" single-line hide-details outlined dense background-color="#fff" style="flex:0 1 350px"
		></v-text-field>
		<div v-if="is_admin" class="ml-3" style="width:200px"><v-autocomplete outlined dense hide-details v-model="county_filter" label="County" :items="counties"></v-autocomplete></div>
		<div class="ml-3" style="width:120px"><v-select outlined dense hide-details v-model="type_filter" label="Type" :items="type_filter_values"></v-select></div>
		<div class="ml-3" style="width:120px"><v-select outlined dense hide-details v-model="status_filter" label="Status" :items="status_filter_values"></v-select></div>
		<div class="ml-3" style="width:120px"><v-select outlined dense hide-details v-model="priority_filter" label="Priority" :items="priority_filter_values"></v-select></div>
		<v-spacer/>
		<v-btn v-if="is_admin" class="ml-3" color="primary" @click="add_new_locations=true" style="letter-spacing:0;text-transform:none;"><v-icon small class="mr-2">fas fa-plus</v-icon> Add New Location(s)</v-btn>
		<!-- <v-btn class="ml-3" fab small color="primary" @click="get_locations"><v-icon>fas fa-sync-alt</v-icon></v-btn> -->
	</div>

	<v-data-table dense
		:headers="headers"
		:items="rows"
		:sort-by="['last_login', 'name_display']"
		:sort-desc="[true,false]"
		:must-sort="true"
		:custom-filter="table_search_filter"
		:search="search"
		:footer-props="footer_options"
		:items-per-page="25"
		class="k-admin-users-table"
	>
		<template v-slot:item="{ item }"><tr>
			<td style="max-width:10px"><v-checkbox class="shrink mt-0 pt-0 d-inline-block" hide-details v-model="batch_select[item.vh_location_id]"></v-checkbox></td>
			<td>{{item.county}}</td>
			<td>
				<v-btn icon x-small class="mr-1" @click="update_lbj_location_id(item.location_record)"><v-icon small>fas fa-edit</v-icon></v-btn>
				{{item.lbj_location_id}}
			</td>
			<td class="text-center">
				<!-- <v-btn icon x-small class="mr-1" @click=""><v-icon small>fas fa-edit</v-icon></v-btn> -->
				{{item.ed_priority_sort_val==99999999 ? '' : item.ed_priority_sort_val}}
			</td>
			<td>
				<v-btn icon x-small class="mr-1" @click="update_location(item.location_record)"><v-icon small>fas fa-edit</v-icon></v-btn>
				{{item.location}}
			</td>
			<td class="text-center">
				<v-menu bottom left><template v-slot:activator="{on}"><v-btn v-on="on" x-small :color="item.location_type=='ed'?'red darken-2':'#ddd'" :dark="item.location_type=='ed'">{{location_types.find(x=>x.value==item.location_type).text}}</v-btn></template>
					<v-list dense>
						<v-list-item v-for="(mitem) in location_types" :key="mitem.value" @click="update_location_record(item.location_record, 'location_type', mitem.value)"><v-list-item-title><v-icon small color="#333" class="mr-2" v-visible="mitem.value==item.location_type">fas fa-circle</v-icon>{{mitem.text}}</v-list-item-title></v-list-item>
					</v-list>
				</v-menu>
			</td>
			<td class="text-center">
				<!-- <v-menu bottom left><template v-slot:activator="{on}"><v-btn v-on="on" x-small :color="item.location_status=='active'?'#666':'#bbb'" :dark="item.location_status=='active'">{{location_statuses.find(x=>x.value==item.location_status).text}}</v-btn></template> -->
				<v-menu bottom left><template v-slot:activator="{on}"><v-btn v-on="on" x-small color="#ddd"><v-icon x-small class="mr-2">fas {{item.location_status=='active'?'fa-check':'fa-times'}}</v-icon>{{location_statuses.find(x=>x.value==item.location_status).text}}</v-btn></template>
					<v-list dense>
						<v-list-item v-for="(mitem) in location_statuses" :key="mitem.value" @click="update_location_record(item.location_record, 'location_status', mitem.value)"><v-list-item-title><v-icon small color="#333" class="mr-2" v-visible="mitem.value==item.location_status">fas fa-circle</v-icon>{{mitem.text}}</v-list-item-title></v-list-item>
					</v-list>
				</v-menu>
			</td>
			<td class="text-center">
				<v-menu bottom left><template v-slot:activator="{on}"><v-btn v-on="on" x-small :color="priority_color(item.default_priority)">{{item.default_priority}}</v-btn></template>
					<v-list dense>
						<v-list-item v-for="(mitem) in priorities" :class="priority_color(mitem.value)" :key="mitem.value" @click="update_location_record(item.location_record, 'default_priority', mitem.value)"><v-list-item-title><v-icon small color="#333" class="mr-2" v-visible="mitem.value==item.default_priority">fas fa-circle</v-icon>{{mitem.text}}</v-list-item-title></v-list-item>
					</v-list>
				</v-menu>
			</td>
		</tr></template>
	</v-data-table>

	<div>
		<v-btn small color="#ccc" @click="select_all" class="mr-2">Select All</v-btn>
		<v-btn v-if="batch_checkbox_selected" small color="#ccc" @click="deselect_all" class="mr-2">Deselect All</v-btn>
		<v-btn v-if="batch_checkbox_selected" small color="red" dark @click="delete_selected"><v-icon small class="mr-2">fas fa-trash-alt</v-icon>Delete Selected Locations</v-btn>
		<v-btn v-if="is_admin" class="ml-2" small color="secondary" @click="process_lbj_locations=true">Process LBJ Locations</v-btn>
	</div>

	<AdminNewLocations v-if="add_new_locations" @close="add_new_locations=false" @refresh="get_locations" />

	<ProcessLBJLocations @close="process_lbj_locations=false" v-if="process_lbj_locations" />
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import AdminNewLocations from './AdminNewLocations'
import ProcessLBJLocations from '@/lbj_location_import/ProcessLBJLocations'

export default {
	components: { AdminNewLocations, ProcessLBJLocations },
	props: {
		// req: { type: String, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		process_lbj_locations: false,
		headers: [
			{ text: '', align: 'left', sortable: false, value:'vh_location_id' },
			{ text: 'County', align: 'left', sortable: true, value:'county' },
			{ text: 'LBJ ID', align: 'center', sortable: true, value:'lbj_location_id' },
			{ text: 'ED Pri.', align: 'center', sortable: true, value:'ed_priority_sort_val' },
			{ text: 'Location Address', align: 'left', sortable: true, value:'location' },
			{ text: 'Type', align: 'center', sortable: true, value:'location_type' },
			{ text: 'Status', align: 'center', sortable: true, value:'location_status' },
			{ text: 'Default Priority', align: 'center', sortable: true, value:'default_priority' },
		],
		footer_options: {
			itemsPerPageOptions: [10,25,100,-1],
		},
		search: '',
		user_being_edited: null,
		add_new_locations: false,
		location_types: [
			{value:'ev', text: 'Early Voting'},
			{value:'ed', text: 'Election Day'},
		],
		location_statuses: [
			{value:'active', text: 'Active'},
			{value:'inactive', text: 'Inactive'},
		],
		batch_select: {},
		county_filter: 'ALL',
		type_filter: 'ALL',
		status_filter: 'ALL',
		priority_filter: 'ALL',
		type_filter_values: ['ALL', 'Early Voting', 'Election Day'],
		status_filter_values: ['ALL', 'Active', 'Inactive'],
	}},
	computed: {
		...mapState(['user_info', 'locations', 'time_zone_correction']),
		...mapGetters(['is_admin']),
		priorities() { return U.shift_priority_select_items() },
		priority_filter_values() {
			let arr = U.shift_priority_select_items()
			arr.unshift({value: 'ALL', text:'ALL'})
			return arr
		},
		counties() {
			let arr = this.$store.state.site_data.counties.concat([])
			arr.unshift('ALL')
			return arr
		},
		rows() {
			let arr = []
			let bs = {}

			for (let l of this.locations) {
				// if user isn't a system admin, only show them locations from their county
				if (!this.is_admin) {
					if (!this.user_info.matches_county(l.county)) continue
					// if (l.county != this.user_info.county) continue -- old version
				}

				// if county_filter is set, check county
				if (this.county_filter != 'ALL') {
					if (l.county != this.county_filter) continue
				}

				// if priority_filter is set, check priority
				if (this.priority_filter != 'ALL') {
					if (this.priority_filter != l.default_priority) continue
				}

				// if status_filter is set, check status
				if (this.status_filter != 'ALL') {
					if (this.status_filter == 'Active' && l.location_status != 'active') continue
					if (this.status_filter == 'Inactive' && l.location_status != 'inactive') continue
				}

				// if type_filter is set, check type
				if (this.type_filter != 'ALL') {
					if (this.type_filter == 'Early Voting' && l.location_type != 'ev') continue
					if (this.type_filter == 'Election Day' && l.location_type != 'ed') continue
				}

				let o = $.extend(true, {}, l)
				// o.location_type = (o.location_type == 'ev') ? 'Early Voting' : 'Election Day'
				o.county_lc = o.county.toLowerCase()
				o.location_lc = o.location.toLowerCase()
				o.ed_priority_sort_val = l.ed_priority_sort_val
				o.location_record = l

				arr.push(o)
				bs[l.vh_location_id] = false
			}

			// initial sort
			arr.sort((a,b) => {
				// first by county
				if (a.county < b.county) return -1
				if (a.county > b.county) return 1

				// then by ev/ed, so that ev comes first
				if (a.location_type < b.location_type) return 1
				if (a.location_type > b.location_type) return -1

				// then by default_priority
				if (a.default_priority != b.default_priority) return a.default_priority - b.default_priority

				// then by ed_priority
				if (a.ed_priority_sort_val != b.ed_priority_sort_val) return a.ed_priority_sort_val - b.ed_priority_sort_val

				// then by location
				return U.natural_sort(a.location_lc, b.location_lc)
			})

			return arr
		},
		n_checkboxes_selected() {
			let i = 0
			for (let index in this.batch_select) {
				if (this.batch_select[index]) ++i
			}
			return i
		},
		batch_checkbox_selected() {
			return this.n_checkboxes_selected > 0
		},
		default_county: {
			get() { return this.$store.state.lst.default_county },
			set(val) { this.$store.commit('lst_set', ['default_county', val]) }
		},
	},
	watch: {
		county_filter(val) {
			this.default_county = val
		},
		'$store.state.lst.default_county'(val) {
			this.county_filter = val
		}
	},
	created() {
		vapp.admin_locations = this

		// if user isn't a system admin, use 'ALL' (users will still be limited to their counties); otherwise if default_county is set, use it
		if (!this.is_admin) this.county_filter = 'ALL'
		else if (this.$store.state.lst.default_county) this.county_filter = this.$store.state.lst.default_county

		// // if user isn't a system admin, always show their county; otherwise if default_county is set, use it
		// if (!this.is_admin) this.county_filter = this.user_info.county
		// else if (this.$store.state.lst.default_county) this.county_filter = this.$store.state.lst.default_county
	},
	mounted() {
		this.get_locations()
	},
	methods: {
		priority_color(priority) { return U.shift_priority_to_color(priority) },
		get_locations() {
			this.$store.dispatch('get_locations').then(()=>{})
		},

		table_search_filter(value, search, item) {
			// value is the value of the column (we can ignore this); search is the search string (could be empty)
			// RETURN FALSE TO HIDE THE ITEM

			// if search is empty, always return true, so the row will SHOW
			if (empty(search)) return true

			search = search.toLowerCase()
			let re = new RegExp(search, 'i')

			// check _lc fields
			if (item.county_lc.search(re) > -1) return true
			if (item.location_lc.search(re) > -1) return true

			// for numbers, check lbj_location_id / vh_location_id
			let n = search*1
			if (!isNaN(n)) {
				if (n > 100000 && item.location_record.lbj_location_id == n) return true
				if (item.location_record.vh_location_id == n) return true
			}

			// if we get to here return false
			return false
		},

		update_lbj_location_id(location_record) {
			this.$prompt({
				title: 'Update Location LBJ ID',
				text: sr('Edit the LBJ ID for location <b>$1</b>:', location_record.location),
				initialValue: location_record.lbj_location_id,
				acceptText: 'Save',
				dialogMaxWidth:800,
			}).then(lbj_location_id => {
				lbj_location_id = $.trim(lbj_location_id)
				// update if we've set lbj_location_id to a value, or it was a value and we're clearing it
				if (!empty(lbj_location_id) || !empty(location_record.lbj_location_id)) {
					this.update_location_record(location_record, 'lbj_location_id', lbj_location_id)
				}
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		update_location(location_record) {
			this.$prompt({
				title: 'Update Location Address',
				text: 'Edit the location address:',
				// promptType: 'textarea',		// default is 'text'
				initialValue: location_record.location,
				acceptText: 'Save',
				dialogMaxWidth:800,
			}).then(location => {
				location = $.trim(location)
				if (!empty(location)) {
					this.update_location_record(location_record, 'location', location)
				}
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		update_location_record(location_record, key, val) {
			// if this location_record is selected, batch update all selected records
			if (this.batch_select[location_record.vh_location_id]) {
				this.batch_update_location_records(location_record, key, val)
				return
			}

			let edited_location = new Location(location_record)
			edited_location[key] = val

			this.$store.dispatch('save_locations', {locations: [edited_location]}).then((result)=>{
				// this.$emit('cancel')
			}).catch((result)=>{
				// shouldn't happen
			})
		},

		deselect_all() {
			this.batch_select = {}
		},

		select_all() {
			let o = {}
			for (let item of this.rows) {
				if (this.table_search_filter(null, this.search, item)) {
					o[item.vh_location_id] = true
				}
			}
			this.batch_select = o
		},

		batch_update_location_records(location_record, key, val) {
			let arr = []
			for (let vh_location_id in this.batch_select) {
				let edited_location = new Location(this.locations.find(x=>x.vh_location_id == vh_location_id))
				edited_location[key] = val
				arr.push(edited_location)
			}

			this.$confirm({
			    title: 'Are you sure?',
			    text: sr('Are you sure you want to update the $1 selected location(s)?', arr.length),
			    acceptText: 'Update',
			}).then(y => {
				this.$store.dispatch('save_locations', {locations: arr}).then((result)=>{
					// this.$emit('cancel')
				}).catch((result)=>{
					// shouldn't happen
				})
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		delete_selected() {
			let vh_location_ids = []
			for (let vh_location_id in this.batch_select) {
				if (this.batch_select[vh_location_id]) vh_location_ids.push(vh_location_id)
			}

			this.$confirm({
			    title: 'Are you sure?',
			    text: sr('Are you sure you want to delete these $1 location(s)?', vh_location_ids.length),
			    acceptText: 'Delete',
				acceptColor: 'red',
			}).then(y => {
				this.$store.dispatch('delete_locations', {vh_location_ids: vh_location_ids}).then((result)=>{
					this.batch_select = {}
					// get shifts -- some shifts might have been deleted
					this.$store.dispatch('get_shifts')

				}).catch((result)=>{
					// shouldn't happen
				})
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},
	}
}
</script>

<style lang="scss">
.k-admin-users-table {
	td {
		font-size:12px!important;
	}
}
</style>
