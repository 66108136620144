class User {
	constructor(data) {
		if (empty(data)) data = {}

		sdp(this, data, 'vh_org_id', vapp.$store.state.vh_org_id)
		sdp(this, data, 'vh_user_id', 0)
		sdp(this, data, 'email', '')
		sdp(this, data, 'name_first', '')
		sdp(this, data, 'name_last', '')

		// get lc versions of name for search purposes
		this.name_first_lc = this.name_first.toLowerCase()
		this.name_last_lc = this.name_last.toLowerCase()

		sdp(this, data, 'roles', '')	// admin, shiftset, dialpad, captain
		sdp(this, data, 'languages', '')

		// make sure roles and languages are all lower-case
		this.roles = this.roles.toLowerCase()
		this.languages = this.languages.toLowerCase()

		// get array version of roles
		this.roles_arr = this.roles.split(/\s*,\s*/)	// admin, shiftset, dialpad, captain

		sdp(this, data, 'training_level', 0)
		sdp(this, data, 'proficiency_level', 2)	// if untrained, 0; otherwise 1-, 2-, or 3-star; default to 2-star
		sdp(this, data, 'residence_state', '')

		// we originally saved "county" as a string; now we use "counties", which is an array
		if (!empty(data.counties)) sdp(this, data, 'counties', [])
		else if (!empty(data.county)) this.counties = [data.county]
		else this.counties = []

		sdp(this, data, 'phone_number', '')

		sdp(this, data, 'other_email', '')
		sdp(this, data, 'lbj_email', '')
		sdp(this, data, 'cal_email', '')

		sdp(this, data, 'added_by_scheduler', false)

		sdp(this, data, 'address', '')
		sdp(this, data, 'geocode', null)

		sdp(this, data, 'created_at', '')
		sdp(this, data, 'login_count', 0)
		sdp(this, data, 'last_login', '')

		// this is set when an admin simulates another user
		sdp(this, data, 'admin_email_doing_simulation', '')
	}

	has_role(role) {
		return this.roles_arr.includes(role)
	}

	is_admin() {
		return this.has_role('admin')
	}

	matches_county(c) {
		// c can be a string or another array
		if (typeof(c) == 'string') {
			for (let i = 0; i < this.counties.length; ++i) {
				if (this.counties[i] == c) return true
			}
			return false
		}

		// if an array, go through each county string in c; if this.counties includes the string, return true
		for (let s of c) {
			for (let i = 0; i < this.counties.length; ++i) {
				if (this.counties[i] == s) return true
			}
		}
		// if we get to here return false
		return false
	}
}
window.User = User
