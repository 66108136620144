<template><v-dialog v-model="dialog_open" persistent max-width="800px"><v-card>
	<v-card-title><b>Edit User Record</b> <span v-if="edited_user.vh_user_id>0" class="ml-2" style="font-size:16px">(User ID {{edited_user.vh_user_id}})</span></v-card-title>
	<v-card-text>
		<v-text-field class="mt-0" outlined dense hide-details label="Last Name" v-model="edited_user.name_last" placeholder="" autocomplete="new-password"></v-text-field>
		<v-text-field class="mt-4" outlined dense hide-details label="First Name" v-model="edited_user.name_first" placeholder="" autocomplete="new-password"></v-text-field>
		<v-text-field class="mt-4" outlined dense hide-details label="Main Email" v-model="edited_user.email" placeholder="" autocomplete="new-password"></v-text-field>
		<v-text-field class="mt-4" outlined dense hide-details label="Alt. Email (optional; usually gmail)" v-model="edited_user.other_email" placeholder="" autocomplete="new-password"></v-text-field>
		<v-text-field class="mt-4" outlined dense hide-details label="LBJ Email (optional; needed if not same as Main Email; could be same as Alt. Email)" v-model="edited_user.lbj_email" placeholder="" autocomplete="new-password"></v-text-field>
		<v-text-field class="mt-4" outlined dense hide-details label="Phone Number" v-model="edited_user.phone_number" placeholder="" autocomplete="new-password"></v-text-field>
		<v-text-field class="mt-4" outlined dense hide-details label="Special Roles (e.g. “admin”, “captain”, “shiftset”, “dialpad”; comma-separated if multiple; “operator” is assumed)" v-model="edited_user.roles" placeholder="" autocomplete="new-password"></v-text-field>
		<v-text-field class="mt-4" outlined dense hide-details label="Languages (e.g. “spanish”, “hatian creole”; comma-separated if multiple; “english” is assumed)" v-model="edited_user.languages" placeholder="" autocomplete="new-password"></v-text-field>

		<div class="mt-4 d-flex align-center">
			<div class="mr-2">County:</div>
			<div><v-autocomplete outlined dense hide-details v-model="edited_user.counties" multiple small-chips deletable-chips :items="site_data.counties" @input="autocomplete_input=null" :search-input.sync="autocomplete_input"></v-autocomplete></div>
			<v-spacer/>
		</div>
		<div class="mt-4 d-flex align-center">
			<div class="mr-2">Training Level (optional):</div>
			<div style="width:180px"><v-select outlined dense hide-details v-model="edited_user.training_level" :items="training_levels"></v-select></div>
			<div class="ml-6 mr-2">Proficiency Level (optional):</div>
			<div>
				<v-icon :color="(edited_user.proficiency_level>=1)?'amber darken-2':'#ddd'" @click="edited_user.proficiency_level=(edited_user.proficiency_level==1)?0:1">fas fa-star</v-icon>
				<v-icon :color="(edited_user.proficiency_level>=2)?'amber darken-2':'#ddd'" @click="edited_user.proficiency_level=2">fas fa-star</v-icon>
				<v-icon :color="(edited_user.proficiency_level>=3)?'amber darken-2':'#ddd'" @click="edited_user.proficiency_level=3">fas fa-star</v-icon>
			</div>
			<v-spacer/>
		</div>
	</v-card-text>
	<v-card-actions class="pl-4 pr-4 pb-4">
		<v-btn color="secondary" @click="$emit('close')" class="mr-1"><v-icon small class="mr-2">fas fa-times</v-icon> Cancel</v-btn>
		<v-spacer></v-spacer>
		<v-btn v-if="user_being_edited.vh_user_id" color="red" dark @click="delete_user" class="mr-1"><v-icon small class="mr-2">fas fa-trash-alt</v-icon> Delete User</v-btn>
		<v-btn color="primary" @click="save_edits"><v-icon small class="mr-2">fas fa-check</v-icon> {{user_being_edited.vh_user_id==0?'Save User':'Save Edits'}}</v-btn>
	</v-card-actions>
</v-card></v-dialog></template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	components: { },
	props: {
		user_being_edited: { type: Object, required: true },
	},
	data() { return {
		dialog_open: true,
		training_levels: [
			{ value: 0, text: '– (No Training)' },
			{ value: 10, text: 'Basic Training' },
			{ value: 20, text: 'Advanced Training' },
		],
		edited_user: {},
		autocomplete_input: '',
	}},
	computed: {
		...mapState(['site_data']),
		...mapGetters(['is_admin']),
	},
	created() {
		this.edited_user = new User(this.user_being_edited)
	},
	mounted() {
		// DEBUG
		vapp.admin_user_edit = this
	},
	methods: {
		save_edits() {
			if (this.edited_user.email) this.edited_user.email = $.trim(this.edited_user.email.toLowerCase())
			if (this.edited_user.other_email) this.edited_user.other_email = $.trim(this.edited_user.other_email.toLowerCase())
			if (this.edited_user.lbj_email) this.edited_user.lbj_email = $.trim(this.edited_user.lbj_email.toLowerCase())
			if (this.edited_user.roles) this.edited_user.roles = $.trim(this.edited_user.roles.toLowerCase())

			// only let full admins set other users to 'admin' roles
			let roles = this.edited_user.roles.split(/\s*,\s*/)
			if (!this.is_admin) {
				if (roles.includes('admin')) {
					this.$alert('Non-site-admins cannot give admin rights to other users.')
					return
				}
			}

			// don't allow spaces in roles
			for (let role of roles) {
				if (role.includes(' ')) {
					this.$alert(`Special roles cannot include spaces. Did you mean to type “${role.replace(/ /g, '_')}”?`)
					return
				}
			}

			if (empty(this.edited_user.email)) {
				this.$alert('You must enter an email for the user.')
				return
			}

			if (this.edited_user.phone_number) {
				// strip non-numbers from phone number
				let pn = this.edited_user.phone_number.replace(/\D/g, '')

				if (pn.length != 10) {
					this.$alert('Please enter a full phone number with area code (10 digits). (Or leave the phone number field blank.)')
					return
				}

				// now format number nicely
				this.edited_user.phone_number = pn.replace(/(\d\d\d)(\d\d\d)(\d\d\d\d)/, '($1)$2-$3')
			}

			this.$store.dispatch('save_users', {users: [this.edited_user]}).then((result)=>{
				this.$emit('close')
			})
		},

		delete_user() {
			this.$confirm({
			    title: 'Are you sure?',
			    text: 'Are you sure you want to delete this user?',
			    acceptText: 'Delete',
				acceptColor: 'red',
				// dialogMaxWidth: 800
			}).then(y => {
				this.$store.dispatch('delete_users', {vh_user_ids: [this.edited_user.vh_user_id]}).then((result)=>{
					this.$emit('close')
				})
			}).catch(n=>{console.log(n)}).finally(f=>{})
		}
	}
}
</script>

<style lang="scss">
</style>
