<template><div>
	<div class="py-4 d-flex" style="margin-left:-30px; margin-right:-30px;">
		<v-text-field
			v-model="search"
			prepend-inner-icon="fa fa-search" clearable clear-icon="fa fa-times-circle" @click="search_clicked"
			label="Search" single-line hide-details outlined dense background-color="#fff" style="flex:0 1 240px"
		></v-text-field>
		<div v-if="is_admin" class="ml-3" style="width:280px"><v-autocomplete outlined dense hide-details multiple v-model="shift_counties" label="County" :items="counties" @input="autocomplete_input=null" :search-input.sync="autocomplete_input"></v-autocomplete></div>
		<div class="ml-3" style="width:240px"><v-select outlined dense hide-details v-model="date_filter" multiple label="Date" :items="date_filter_values"></v-select></div>
		<div class="ml-3" style="width:180px"><v-select outlined dense hide-details multiple v-model="priority_filter" label="Priority" :items="priority_filter_values"></v-select></div>
		<div class="ml-3" style="width:180px"><v-select outlined dense hide-details multiple v-model="status_filter" label="Status" :items="status_filter_values"></v-select></div>
		<v-spacer/>
		<v-btn class="ml-3" color="primary" @click="new_shift_clicked" style="letter-spacing:0;text-transform:none;"><v-icon small class="mr-2">fas fa-plus</v-icon> Add Shift(s)</v-btn>

		<v-menu bottom><template v-slot:activator="{on}"><v-btn v-on="on" class="ml-3 k-tight-btn k-nocaps-btn" color="primary"><v-icon small class="mr-1">fas fa-file-export</v-icon>Export…</v-btn></template>
			<v-list min-width="250">
				<v-list-item @click="export_normal('unconfirmed')"><v-list-item-title>Export shifts to spreadsheet</v-list-item-title></v-list-item>
				<v-list-item v-if="is_admin" @click="export_to_lbj(false)"><v-list-item-title>Export to LBJ (2022 method)</v-list-item-title></v-list-item>
				<v-list-item v-if="is_admin" @click="export_to_lbj_csv()"><v-list-item-title>Export to LBJ via CSV (2024 method)</v-list-item-title></v-list-item>
			</v-list>
		</v-menu>

		<!-- <v-btn class="ml-3" fab small color="primary" @click="get_shifts"><v-icon>fas fa-sync-alt</v-icon></v-btn> -->
	</div>

	<div class="k-admin-shifts-table-wrapper elevation-3">
		<v-data-table dense
			:headers="headers"
			:items="rows"
			:custom-filter="table_search_filter"
			:search="search"
			:footer-props="footer_options"
			:items-per-page="25"
			:fixed-header="true"
			:height="'calc(100vh - 100px)'"
			class="k-admin-shifts-table"
		>
			<template v-slot:item="{ item }"><tr :class="priority_color(item.shift.priority)">
				<td style="max-width:10px"><v-checkbox class="shrink mt-0 pt-0 d-inline-block" hide-details v-model="batch_select[item.vh_shift_id]"></v-checkbox></td>
				<td style="width:10px" class="text-center"><v-btn text x-small @click="edit_shift(item.shift)"><v-icon small class="mr-2">fas fa-edit</v-icon> {{item.vh_shift_id}}</v-btn></td>
				<td>{{item.lbj_shift_database_id}}</td>
				<td><nobr>{{item.county}}</nobr></td>
				<td v-html="row_location_html(item)"></td>
				<td class="text-center"><nobr>
					<b class="mr-2" style="font-size:14px; border-radius:2px;padding:0 3px;" :class="item.shift.date==site_data.election_date?'red darken-3 white--text':''">{{item.date_display}}</b>
					<v-menu left max-height="400"><template v-slot:activator="{on}"><v-btn v-on="on" x-small color="#fff" @click="">{{item.shift.start_time}}</v-btn></template>
						<v-list dense>
							<v-list-item v-for="(mitem) in times" :key="mitem" @click="update_shift(item.shift, 'start_time', mitem)"><v-list-item-title><v-icon small color="#333" class="mr-2" v-visible="mitem==item.shift.start_time">fas fa-circle</v-icon>{{mitem}}</v-list-item-title></v-list-item>
						</v-list>
					</v-menu>
					–
					<v-menu bottom right max-height="400"><template v-slot:activator="{on}"><v-btn v-on="on" x-small color="#fff" @click="">{{item.shift.end_time}}</v-btn></template>
						<v-list dense>
							<v-list-item v-for="(mitem) in times" :key="mitem" @click="update_shift(item.shift, 'end_time', mitem)"><v-list-item-title><v-icon small color="#333" class="mr-2" v-visible="mitem==item.shift.end_time">fas fa-circle</v-icon>{{mitem}}</v-list-item-title></v-list-item>
						</v-list>
					</v-menu>
				</nobr></td>
				<td class="text-center">
					<v-menu bottom left><template v-slot:activator="{on}"><v-btn v-on="on" x-small color="#fff" @click="">{{item.priority_display}}</v-btn></template>
						<v-list dense>
							<v-list-item v-for="(pitem) in priorities" :class="priority_color(pitem.value)" :key="pitem.value" @click="update_shift(item.shift, 'priority', pitem.value)"><v-list-item-title><v-icon small color="#333" class="mr-2" v-visible="pitem.value==item.priority">fas fa-circle</v-icon>{{pitem.text}}</v-list-item-title></v-list-item>
						</v-list>
					</v-menu>
				</td>
				<td class="text-left">
					<v-menu bottom left><template v-slot:activator="{on}"><v-btn v-on="on" x-small color="#fff" @click=""><v-icon small color="#333" class="mr-2">{{status_icon(item.status)}}</v-icon>{{item.status_display}}</v-btn></template>
						<v-list dense>
							<v-list-item v-for="(sitem) in statuses" :key="sitem.value" @click="update_shift(item.shift, 'status', sitem.value)"><v-list-item-title><v-icon small color="#333" class="mr-2" v-visible="sitem.value==item.status">fas fa-circle</v-icon>{{sitem.text}}</v-list-item-title></v-list-item>
						</v-list>
					</v-menu>
				</td>
				<td @click="volunteer_cell_clicked(item)" style="cursor:pointer;" :class="shift_volunteer_being_edited_id==item.vh_shift_id?'brown lighten-4':''">
					<UserInfo v-if="user_showing_shift_id&&user_showing_shift_id==item.vh_shift_id" :user="item.volunteer_user" @close="user_showing_shift_id=null" :show_edit_btn="true" @edit="volunteer_cell_clicked(item,'edit')" style="margin-left:-24px;margin-top:22px"></UserInfo>
					<ShiftUserEditor v-if="shift_volunteer_being_edited_id==item.vh_shift_id" :shift="item.shift" :field="'volunteer_email'" @close="shift_volunteer_being_edited_id=null" @save="update_shift" style="margin-left:-200px;margin-top:24px;"/>
					<nobr><v-icon x-small color="rgba(128,128,128,0.5)" style="margin-top:-2px">fas fa-info-circle</v-icon> {{row_volunteer_html(item)}}</nobr>
				</td>
				<td>{{item.admin_display}}</td>
			</tr></template>
		</v-data-table>

		<div class="mx-2 mb-2" style="margin-top:-20px;">
			<v-btn small color="#ccc" @click="select_all" class="mr-2">Select All</v-btn>
			<v-btn v-if="batch_checkbox_selected" small color="#ccc" @click="deselect_all" class="mr-2">Deselect All</v-btn>
			<v-btn v-if="batch_checkbox_selected" small color="red" dark @click="delete_selected"><v-icon small class="mr-2">fas fa-trash-alt</v-icon>Delete Selected Shifts</v-btn>
		</div>
	</div>

	<AdminEditShifts v-if="shift_being_edited" :shift_being_edited="shift_being_edited" @close="shift_being_edited=null"></AdminEditShifts>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import UserInfo from '../users/UserInfo'
import AdminEditShifts from './AdminEditShifts'
import ShiftUserEditor from './ShiftUserEditor'

export default {
	components: { UserInfo, AdminEditShifts, ShiftUserEditor },
	props: {
		// req: { type: String, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		headers: [
			{ text: '', align: 'left', sortable: false, value:'vh_shift_id' },
			{ text: 'VP Shift ID', align: 'left', sortable: false, value:'vh_shift_id' },
			{ text: 'LBJ Shift ID', align: 'left', sortable: true, value:'lbj_shift_database_id' },
			{ text: 'County', align: 'left', sortable: true, value:'county' },
			{ text: 'Location', align: 'left', sortable: false, value:'location' },
			{ text: 'Date/Time', align: 'center', sortable: true, value:'date_sort_val' },
			{ text: 'Priority', align: 'center', sortable: true, value:'priority' },
			{ text: 'Status', align: 'center', sortable: true, value:'status_display' },
			{ text: 'Volunteer', align: 'left', sortable: false, value:'volunteer_display' },
			{ text: 'Leader', align: 'left', sortable: false, value:'admin_display' },
		],
		footer_options: {
			itemsPerPageOptions: [10,25,100,-1],
		},
		search: '',
		shift_being_edited: null,
		user_showing_shift_id: null,
		batch_select: {},
		shift_volunteer_being_edited_id: null,
		date_filter: ['UPCOMING'],
		priority_filter: ['ALL'],
		status_filter: ['ALL'],
		excluded_ALL_counties: ['Hotline', 'Test'],
		search_info_needed: false,
		search_info_status: '',
		autocomplete_input: '',
	}},
	computed: {
		...mapState(['user_info', 'shifts', 'users', 'locations', 'time_zone_correction', 'times', 'site_data', 'today_date']),
		...mapGetters(['is_admin', 'all_dates', 'all_dates_hotline', 'user_hash']),
		statuses() { return U.shift_status_select_items() },
		show_all_counties() { return this.shift_counties.includes('ALL (except Hotline/Test)') },
		status_filter_values() {
			let arr = U.shift_status_select_items()
			arr.unshift({value: 'ALL', text:'ALL'})
			return arr
		},
		priorities() { return U.shift_priority_select_items() },
		priority_filter_values() {
			let arr = U.shift_priority_select_items()
			arr.unshift({value: 'ALL', text:'ALL'})
			return arr
		},
		date_filter_values() {
			let arr = []
			let all_dates
			if (this.shift_counties.includes('Hotline')) all_dates = this.$store.getters.all_dates_hotline
			else all_dates = this.$store.getters.all_dates
			// else if (!this.show_all_counties) all_dates = this.$store.getters.all_dates
			// else if (this.$store.getters.all_dates.length > this.$store.getters.all_dates_hotline.length) all_dates = this.$store.getters.all_dates 
			// else all_dates = this.$store.getters.all_dates_hotline

			for (let d of all_dates) arr.push($.extend(true, {}, d))
			arr.unshift({value: 'UPCOMING', text:'UPCOMING'})
			arr.unshift({value: 'ALL', text:'ALL'})
			return arr
		},
		counties() {
			let arr = this.$store.state.site_data.counties.concat([])
			arr.unshift('NONE')
			arr.unshift('ALL (except Hotline/Test)')
			return arr
		},
		rows() {
			if (this.locations.length == 0 || this.shifts.length == 0 || this.users.length == 0) return []

			let arr = []

			for (let s of this.shifts) {
				// if user isn't a system admin, only show them shifts from their county
				if (!this.is_admin) {
					if (!this.user_info.matches_county(s.county)) continue
				}
				
				// if county_filter is 'ALL...'
				if (this.show_all_counties) {
					// we show all counties except excluded_ALL_counties
					if (this.excluded_ALL_counties.includes(s.county)) continue

				// else skip if county isn't in shift_counties
				} else if (!this.show_all_counties) {
					if (!this.shift_counties.includes(s.county)) continue
				}

				// if date_filter is set, check date
				if (!this.date_filter.includes('ALL')) {
					if (this.date_filter.includes('UPCOMING')) {
						if (s.date < this.today_date) continue
					} else {
						if (!this.date_filter.includes(s.date)) continue
					}
				}

				// if priority_filter is set, check priority
				if (this.priority_filter[0] != 'ALL') {
					if (!this.priority_filter.includes(s.priority)) continue
				}

				// if status_filter is set, check status
				if (this.status_filter[0] != 'ALL') {
					if (!this.status_filter.includes(s.status)) continue
				}

				let date_display = s.date.replace(/^20\d+-/, '')
				let date_sort_val = date_display
				if (s.start_time) date_sort_val += ' ' + s.start_time
				if (s.end_time) date_sort_val += '-' + s.end_time

				let priority_display = s.priority + ''
				let status_display = U.shift_status_description(s.status)
				let admin_display = s.admin_email

				let location = ''
				let lbj_location_id = ''
				// only look up location if we need it for search
				if (this.search_info_needed) {
					let location_record = this.locations.find(x=>x.vh_location_id==s.vh_location_id)
					location = (!location_record) ? '???' : location_record.location
					lbj_location_id = (!location_record) ? '' : location_record.lbj_location_id
				}

				let volunteer_display = s.volunteer_email
				let volunteer_search = s.volunteer_email
				let u
				// only look up user if we need it for search
				if (this.search_info_needed) {
					u = this.user_hash[s.volunteer_email]
					if (!empty(u)) {
						volunteer_display = sr('$1, $2', u.name_last, u.name_first)
						// add first/last name to search filter
						volunteer_search += ' ' + volunteer_display
					}
				}

				let o = {
					vh_shift_id: s.vh_shift_id,
					lbj_shift_database_id: s.lbj_shift_database_id,
					county: s.county,
					location: location,
					lbj_location_id: lbj_location_id,
					date_display: date_display,
					date_sort_val: date_sort_val,
					priority: s.priority,
					priority_display: priority_display,
					status: s.status,
					status_display: status_display,
					volunteer_display: volunteer_display,
					volunteer_user: u,
					admin_display: admin_display,

					shift: s,
				}

				o.county_lc = o.county.toLowerCase()
				if (this.search_info_needed) {
					o.location_lc = o.location.toLowerCase()
					o.date_display_lc = o.date_display.toLowerCase()
					o.priority_display_lc = o.priority_display.toLowerCase()
					o.volunteer_display_lc = volunteer_search.toLowerCase()
					o.admin_display_lc = o.admin_display.toLowerCase()
				}

				arr.push(o)
			}

			// initial sort
			arr.sort((a,b) => {
				// first by county
				if (a.county_lc < b.county_lc) return -1
				if (a.county_lc > b.county_lc) return 1

				// then by priority
				if (a.priority != b.priority) return a.priority - b.priority

				// then by date/time
				if (a.date_sort_val < b.date_sort_val) return -1
				if (a.date_sort_val > b.date_sort_val) return 1

				// then by location (all we have now is vh_location_id)
				return a.shift.vh_location_id - b.shift.vh_location_id
				// return U.natural_sort(a.location_lc, b.location_lc)
			})

			if (this.search_info_status == 'loading') {
				this.search_info_status = 'loaded'
				U.loading_stop()
			}

			return arr
		},
		default_county() { return this.$store.state.lst.default_county },
		shift_counties: {
			get() { return this.$store.state.lst.shift_counties },
			set(val) { 
				// if 'NONE' is selected, deselect everything
				if (val.includes('NONE')) val = []
				// if 'more than one thing is selected and 'ALL' is the last thing, they just chose 'ALL', so deselect everything else'
				else if (val[val.length-1] == 'ALL (except Hotline/Test)') val = ['ALL (except Hotline/Test)']
				// else a particular county was last selected, so...
				else {
					// make sure 'ALL' is not there, unless 'Hotline' or 'Test' was chosen
					let last_chosen = val[val.length-1]
					if (!(last_chosen == 'Hotline' || last_chosen == 'Test')) {
						let i = val.indexOf('ALL (except Hotline/Test)')
						if (i > -1) val.splice(i, 1)
					}
					// and set the last-selected county as default_county
					this.$store.commit('lst_set', ['default_county', last_chosen])
				}

				// now save to localstorage
				this.$store.commit('lst_set', ['shift_counties', val]) 

				this.search_info_needed = false	// reset this when we change filters
			}
		},
		n_checkboxes_selected() {
			let i = 0
			for (let index in this.batch_select) {
				if (this.batch_select[index]) ++i
			}
			return i
		},
		batch_checkbox_selected() {
			return this.n_checkboxes_selected > 0
		},
	},
	watch: {
		date_filter(val) {
			if (val.length > 1) {
				// if 'ALL' is first in the array, the user just chose a specific value, so remove 'ALL'; same for UPCOMING
				if (val[0] == 'ALL') {
					this.date_filter.shift()
				} else if (val[0] == 'UPCOMING') {
					this.date_filter.shift()
				// else if 'ALL' is last in the array, the user just chose "all", so remove everything except 'ALL'; same for UPCOMING
				} else if (val[val.length-1] == 'ALL') {
					this.date_filter = ['ALL']
				} else if (val[val.length-1] == 'UPCOMING') {
					this.date_filter = ['UPCOMING']
				}
			}
			this.search_info_needed = false	// reset this when we change filters
		},
		status_filter(val) {
			if (val.length > 1) {
				// if 'ALL' is first in the array, the user just chose a specific value, so remove 'ALL'
				if (val[0] == 'ALL') {
					this.status_filter.shift()
				} else if (val[val.length-1] == 'ALL') {
					// else if 'ALL' is last in the array, the user just chose "all", so remove everything except 'ALL'
					this.status_filter = ['ALL']
				}
			} else if (val.length == 0) {
				this.status_filter = ['ALL']
			}
			this.search_info_needed = false	// reset this when we change filters
		},
		priority_filter(val) {
			if (val.length > 1) {
				// if 'ALL' is first in the array, the user just chose a specific value, so remove 'ALL'
				if (val[0] == 'ALL') {
					this.priority_filter.shift()
				} else if (val[val.length-1] == 'ALL') {
					// else if 'ALL' is last in the array, the user just chose "all", so remove everything except 'ALL'
					this.priority_filter = ['ALL']
				}
			} else if (val.length == 0) {
				this.priority_filter = ['ALL']
			}
			this.search_info_needed = false	// reset this when we change filters
		},
	},
	created() {
		this.get_shifts()

		// if user isn't a system admin, select all counties
		if (!this.is_admin) this.shift_counties = ['ALL (except Hotline/Test)', 'Hotline', 'Test']
	},
	mounted() {
		vapp.admin_shifts_component = this
	},
	methods: {
		status_icon(status) { return U.shift_status_to_icon(status) },
		priority_color(priority) { return U.shift_priority_to_color(priority) },
		get_shifts() {
			if (this.shifts.length == 0) this.$store.dispatch('get_shifts')
			if (this.users.length == 0) this.$store.dispatch('get_users')
			if (this.locations.length == 0) this.$store.dispatch('get_locations')
		},

		row_location_html(item) {
			// console.log('row_location_html')
			let location_record = this.locations.find(x=>x.vh_location_id==item.shift.vh_location_id)
			if (!location_record) return '???'
			return sr('$1<b class="grey--text text--darken-3 ml-2">[$2]</b>', location_record.location, location_record.lbj_location_id ? location_record.lbj_location_id : '—')
			// {{item.location}}<b class="grey--text text--darken-3 ml-2">[{{item.lbj_location_id ? item.lbj_location_id : '—'}}]</b>
		},

		row_volunteer_html(item) {
			// console.log('row_volunteer_html')
			let u = this.user_hash[item.shift.volunteer_email]
			if (!empty(u)) {
				return sr('$1, $2', u.name_last, u.name_first)
			}
			return item.shift.volunteer_email
		},

		search_clicked() {
			if (!this.search_info_needed) {
				console.log('search_clicked; loading search info')
				U.loading_start('Loading search data…')
				setTimeout(x=>{
					this.search_info_status = 'loading'
					this.search_info_needed = true
				}, 100)
			}
		},

		table_search_filter(value, search, item) {
			if (!this.search_info_needed || this.search_info_status == 'loading') {
				if (!this.search_info_needed) {
					this.search_info_status = 'loading'
					this.search_info_needed = true
				}
				return
			}

			// value is the value of the column (we can ignore this); search is the search string (could be empty)
			// RETURN FALSE TO HIDE THE ITEM

			// if search is empty, always return true, so the row will SHOW
			if (empty(search)) return true

			search = search.toLowerCase()
			let re = new RegExp(search, 'i')

			// check shift id and lbj_shift_database_id
			if (item.vh_shift_id == search) return true
			if (item.lbj_shift_database_id == search) return true

			// check lbj_location_id
			if (item.lbj_location_id == search) return true

			// check _lc fields
			if (item.county_lc.search(re) > -1) return true
			if (item.location_lc.search(re) > -1) return true
			if (item.volunteer_display_lc.search(re) > -1) return true
			if (item.admin_display_lc.search(re) > -1) return true

			// if we get to here return false
			return false
		},

		update_shift(shift, key, val) {
			this.shift_volunteer_being_edited_id = null

			// if this shift is selected, batch update all selected records
			if (this.batch_select[shift.vh_shift_id]) {
				this.batch_update_shifts(shift, key, val)
				return
			}

			let edited_shift = new Shift(shift)
			edited_shift[key] = val

			// if we're moving status to not_open or open, clear volunteer_email
			if (key == 'status' && (val == 'not_open' || val == 'open')) edited_shift.volunteer_email = ''

			// if we're setting volunteer_email...
			if (key == 'volunteer_email') {
				// if we're clearing the volunteer and status wasn't open or not_open, set to open
				if (val == '' && !(edited_shift.status == 'not_open' || edited_shift.status == 'open')) {
					edited_shift.status = 'open'

				// if we're setting the volunteer and status was open or not_open, set to filled
				} else if (val != '' && (edited_shift.status == 'not_open' || edited_shift.status == 'open')) {
					edited_shift.status = 'filled'
				}
			}

			if (U.check_for_shift_overlap(this.shifts, this.locations, edited_shift)) return

			this.$store.dispatch('save_shift', {shift: edited_shift}).then((result)=>{
				// this.$emit('cancel')
			}).catch((result)=>{
				// shouldn't happen
			})
		},

		deselect_all() {
			this.batch_select = {}
		},

		select_all() {
			let o = {}
			for (let item of this.rows) {
				if (this.table_search_filter(null, this.search, item)) {
					o[item.vh_shift_id] = true
				}
			}
			this.batch_select = o
		},

		batch_update_shifts(shift, key, val) {
			let arr = []
			for (let vh_shift_id in this.batch_select) {
				let edited_shift = new Shift(this.shifts.find(x=>x.vh_shift_id == vh_shift_id))
				edited_shift[key] = val
				arr.push(edited_shift)
			}

			this.$confirm({
			    title: 'Are you sure?',
			    text: sr('Are you sure you want to update the $1 selected shift(s)?', arr.length),
			    acceptText: 'Update',
			}).then(y => {
				this.$store.dispatch('batch_update_shifts', {shifts: arr}).then((result)=>{
					// this.$emit('cancel')
				}).catch((result)=>{
					// shouldn't happen
				})
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		delete_selected() {
			let vh_shift_ids = []
			for (let vh_shift_id in this.batch_select) {
				if (this.batch_select[vh_shift_id]) vh_shift_ids.push(vh_shift_id)
			}

			this.$confirm({
			    title: 'Are you sure?',
			    text: sr('Are you sure you want to delete these $1 shift(s)?', vh_shift_ids.length),
			    acceptText: 'Delete',
				acceptColor: 'red',
			}).then(y => {
				this.$store.dispatch('delete_shifts', {vh_shift_ids: vh_shift_ids}).then((result)=>{
					this.batch_select = {}

				}).catch((result)=>{
					// shouldn't happen
				})
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},


		edit_shift(shift) {
			this.shift_being_edited = shift
		},

		new_shift_clicked() {
			this.shift_being_edited = new Shift({county:this.default_county})
		},

		volunteer_cell_clicked(item, flag) {
			if (!item.volunteer_user || flag == 'edit') {
				this.shift_volunteer_being_edited_id = item.vh_shift_id
				this.user_showing_shift_id = null
			} else {
				this.user_showing_shift_id = item.vh_shift_id
			}
		},

		export_normal(flag) {
			if (!this.search_info_needed || this.search_info_status == 'loading') {
				if (!this.search_info_needed) {
					console.log('export selected; loading search info')
					U.loading_start('Loading location data…')
					setTimeout(x=>{
						this.search_info_status = 'loading'
						this.search_info_needed = true
						setTimeout(x=>this.export_normal(flag), 500)
					}, 100)
				} else {
					console.log('export selected; status == loading')
					setTimeout(x=>this.export_normal(flag), 100)
				}
				return
			}

			if (flag == 'unconfirmed') {
				this.$confirm({
					text: sr('This will export the $1 currently-filtered shifts as a TSV (tab-separated values) file that you can open in Excel or another spreadsheet program.', this.rows.length),
					acceptText: 'Proceed',
					dialogMaxWidth: 600,
					focusBtn: true,		// focus on the accept btn when dialog is rendered
				}).then(y => {
					this.export_normal('confirmed', null)
				}).catch(n=>{console.log(n)}).finally(f=>{})
				return
			}

			let export_rows = []
			// header row
			export_rows.push([
				'Shift ID',
				'County',
				'Location Address',
				'Date',
				'Time',
				'Status',
				'Volunteer Name',
				'Volunteer Email',
				'Volunteer Phone',
				'Leader',
			])

			let year = date.format(new Date(), 'YYYY')

			for (let row of this.rows) {
				let export_row = []

				export_row.push(row.vh_shift_id)
				export_row.push(row.county)
				export_row.push(row.location.replace(/\s+/g, ' '))
				export_row.push(row.date_display)
				export_row.push(row.shift.start_time + '-' + row.shift.end_time)
				export_row.push(row.status)
				export_row.push(row.volunteer_display)
				export_row.push(row.volunteer_user ? row.volunteer_user.email : '')
				export_row.push(row.volunteer_user ? row.volunteer_user.phone_number : '')
				export_row.push(row.admin_display)

				export_rows.push(export_row)
			}

			let filename = sr('shift-export-$1.tsv', date.format(new Date(), 'YYYY-MM-DD'))
			// console.log(filename)
			// console.log(export_rows)
			// return
			U.download_file(TSV.stringify(export_rows), filename)
		},

		export_to_lbj(email_all_precincts) {
			if (!email_all_precincts) {
				this.$confirm({
					title: 'Export to LBJ',
					text: 'What value would you like send in the “email-all-precincts” flag — “TRUE” or “FALSE”?',
					acceptText: 'TRUE',
					cancelText: 'FALSE',
					dialogMaxWidth: 650,
				}).then(y => {
					this.export_to_lbj('TRUE')
				}).catch(n=>{
					this.export_to_lbj('FALSE')
				}).finally(f=>{})
				return
			}

			let export_rows = []

			let year = date.format(new Date(), 'YYYY')

			for (let shift of this.shifts) {
				// note that this tool is only available to system admins; but include this next clause just to be safe
				// if user isn't a system admin, only show them shifts from their county
				if (!this.is_admin) {
					if (!this.user_info.matches_county(shift.county)) continue
					// if (shift.county != this.user_info.county) continue
				}

				// if county_filter is 'ALL...'
				if (this.show_all_counties) {
					// we show all counties except excluded_ALL_counties
					if (this.excluded_ALL_counties.includes(shift.county)) continue

				// else skip if county isn't in shift_counties
				} else if (!this.show_all_counties) {
					if (!this.shift_counties.includes(shift.county)) continue
				}


				// if date_filter is set, check date
				if (!this.date_filter.includes('ALL')) {
					if (this.date_filter.includes('UPCOMING')) {
						if (shift.date < this.today_date) continue
					} else {
						if (!this.date_filter.includes(shift.date)) continue
					}
				}

				// if priority_filter is set, check priority
				if (this.priority_filter[0] != 'ALL') {
					if (!this.priority_filter.includes(shift.priority)) continue
				}

				// ignore status filter

				// export rows that are either status=='filled' OR lbj_shift_database_id is non-empty and not 'deleted' (in which case we have to delete the shift in LBJ)
				if (!(shift.filled_for_lbj() || (!empty(shift.lbj_shift_database_id) && shift.lbj_shift_database_id != 'DELETED'))) {
					continue
				}

				// get the location record for the shift and the user record for the shift
				let location = this.locations.find(x=>x.vh_location_id==shift.vh_location_id)

				// get the email to use for the shift. first try to find the user record from the volunteer_email, then other_email, then lbj_email
				let user = this.users.find(x=>x.email==shift.volunteer_email)
				if (!user) user = this.users.find(x=>x.email==shift.other_email)
				if (!user) user = this.users.find(x=>x.email==shift.lbj_email)

				// now, if we didn't find a user, just use volunteer_email; otherwise use lbj_email if there or the main email otherwise
				let export_email = shift.volunteer_email
				if (user) {
					if (user.lbj_email) export_email = user.lbj_email
					else export_email = user.email
				}

				// push the values into the export_row demanded by the LBJ bulk import tool
				let export_row = []

				// database-id -- could be empty, a number, or 'DELETED' (in which case we clear it, so that LBJ will give us the new id)
				let lbj_shift_database_id = shift.lbj_shift_database_id
				if (lbj_shift_database_id == 'DELETED') lbj_shift_database_id = ''
				export_row.push(lbj_shift_database_id)

				// delete-requested -- TRUE if shift is now unfilled and we have a lbj shift id, or FALSE otherwise
				let dr = 'FALSE'
				if (!shift.filled_for_lbj() && !empty(shift.lbj_shift_database_id) && shift.lbj_shift_database_id != 'DELETED') dr = 'TRUE'
				export_row.push(dr)

				// volunteer-id email
				export_row.push(export_email)

				// shift-type
				export_row.push('poll')

				// shift-name - skip
				export_row.push('')

				// boiler-room-id - skip
				export_row.push('')

				// location-id
				export_row.push(location.lbj_location_id)

				// precinct-id - skip
				export_row.push('')
				
				// board-of-elections-id - skip
				export_row.push('')

				// shift-date	YYYY-MM-DD
				export_row.push(year + '-' + shift.date)

				// shift-time - skip
				export_row.push('')
				
				// shift-start-time	hh:mm
				export_row.push(shift.start_time.replace(/^(\d:)/, '0$1'))

				// shift-end-time	hh:mm
				export_row.push(shift.end_time.replace(/^(\d:)/, '0$1'))

				// place-status:	inside, unless "outside" is in shift location
				let place_status = 'inside'
				if (location.location.indexOf('outside') > -1 ) place_status = 'outside'
				export_row.push(place_status)

				// rover - skip
				export_row.push('')
				
				// email-all-precincts -- based on user input
				export_row.push(email_all_precincts)
				
				// notes - add vh_shift_id here, so we can re-import after LBJ process runs
				export_row.push(shift.vh_shift_id)

				// tier - skip
				export_row.push('')
				
				// street-address - skip
				export_row.push('')
				
				// city - skip
				export_row.push('')
				
				// county - skip
				export_row.push('')

				// Push row!!
				export_rows.push(export_row)
			}

			// let filename = sr('lbj-bulk-upload-file-$1.tsv', date.format(new Date(), 'YYYY-MM-DD'))
			// U.download_file(TSV.stringify(export_rows), filename)
			this.export_to_lbj_2(export_rows)
		},

		export_to_lbj_2(export_rows) {
			let msg = sr('Processed <b>$1 shift(s)</b> for LBJ bulk upload.', export_rows.length)
			msg += '<div class="mt-2">Note: this includes shifts that a) match the County, Date, and Priority filters, AND b) either have a volunteer selected, OR previously had a volunteer selected and are now unfilled.</div>'
			msg += '<div class="mt-2">To enter the shifts in LBJ, then update the website database, start by clicking the button below to “COPY LBJ DATA TO CLIPBOARD”.</div>'

			this.$confirm({
				title: 'Export to LBJ',
				text: msg,
				acceptText: 'COPY LBJ DATA TO CLIPBOARD',
				dialogMaxWidth: '620px',
				focusBtn: true,		// focus on the accept btn when dialog is rendered
			}).then(y => {
				let text = ''
				for (let row of export_rows) {
					text += row.join('\t') + '\n'
				}
				U.copy_to_clipboard(text)
				this.export_to_lbj_3(export_rows)
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		export_to_lbj_3(export_rows) {			
			let msg = sr('The LBJ data should now be copied to your clipboard.', export_rows.length)
			msg += '<div class="mt-2">To proceed from here:</div><ol class="my-2">'
			msg += '<li>PASTE the LBJ data into the LBJ Bulk Import Tool spreadsheet</li>'
			msg += '<li>Click “Run Import” in the spreadsheet</li>'
			msg += '<li>Once the import has completed, select and COPY the rows out of the spreadsheet</li>'
			msg += '<li>PASTE the data from the spreadsheet into the box below</li>'
			msg += '<li>Click “UPDATE WEBSITE DATABASE”</li>'
			msg += '</ol>'

			this.$prompt({
				title: 'Export to LBJ',
				text: msg,
				promptType: 'textarea',		// default is 'text'
				initialValue: '',
				acceptText: 'UPDATE WEBSITE DATABASE',
				dialogMaxWidth: '1100px',
			}).then(lbj_lines => {
				this.export_to_lbj_4(export_rows, lbj_lines)
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		export_to_lbj_4(export_rows, lbj_lines) {
			// process incoming lbj_lines; save updated lbj_shift_database_id values
			lbj_lines = lbj_lines.split(/\n/)
			let errors = 0
			let updated_shifts = []
			for (let i = 0; i < lbj_lines.length; ++i) {
				let line = lbj_lines[i]
				if (empty(line)) continue

				// skip header row if sent in
				if (line.indexOf('database-id') > -1) continue
				line = line.split(/\t/)

				// get vh_shift_id back out of the notes field, and get the lbj_shift_database_id field
				let vh_shift_id = $.trim(line[16])
				let lbj_shift_database_id = $.trim(line[0])

				if (empty(vh_shift_id)) {
					console.log(sr('ERROR in line $1: no vh_shift_id in notes field', i+1), line)
					++errors
					continue
				}

				let shift = this.shifts.find(x=>x.vh_shift_id == vh_shift_id)
				if (empty(shift)) {
					console.log(sr('ERROR in line $1: couldn’t find shift from vh_shift_id', i+1), line)
					++errors
					continue
				}

				// if the shift was NOT filled_for_lbj, we have to delete the lbj_shift_database_id
				if (!shift.filled_for_lbj()) {
					updated_shifts.push({vh_shift_id: vh_shift_id, lbj_shift_database_id: 'DELETED'})
				} else {
					if (empty(lbj_shift_database_id)) {
						console.log(sr('ERROR in line $1: no lbj_shift_database_id field for unfilled shift', i+1), line)
						++errors
						continue
					}
					// if lbj_shift_database_id didn't change, we don't have to change anything here (probably the shift volunteer changed)
					if (shift.lbj_shift_database_id != lbj_shift_database_id) {
						updated_shifts.push({vh_shift_id: vh_shift_id, lbj_shift_database_id: lbj_shift_database_id})
					}
				}
			}

			let msg = ''
			if (errors > 0) {
				msg = sr('We encountered <b>$1 error(s)</b> in processing the data coming back from LBJ (tap cmd-opt-j to see the errors in the console).', errors)
				if (updated_shifts.length > 0) msg += ' However, some of the data coming back from LBJ was able to be processed.'
			}
			if (updated_shifts.length > 0) {
				if (msg) msg += '<br><br>'
				msg += sr('Click “CONFIRM” to <b>update $1 shift record(s)</b> with updated lbj_shift_database_id values. If this doesn’t seem right, click “TRY AGAIN” to return to the previous screen and try pasting the data from LBJ in again.', updated_shifts.length)

				this.$confirm({
					title: 'Export to LBJ',
					text: msg,
					acceptText: 'CONFIRM',
					cancelText: 'Try Again',
					dialogMaxWidth: 650,
				}).then(y => {
					this.$store.dispatch('batch_update_shifts', {shifts: updated_shifts}).then((result)=>{
						this.$alert('Process complete!')
					}).catch((result)=>{
						// shouldn't happen
					})

				}).catch(n=>{
					// if user clicks cancel, go back to export_to_lbj_3
					this.export_to_lbj_3(export_rows)
				}).finally(f=>{})

			} else {
				if (!msg) msg = 'No updates to the website database were required. If this doesn’t seem right, click “TRY AGAIN” to return to the previous screen and try pasting the data from LBJ in again.'
				else msg += 'If this doesn’t seem right, click “TRY AGAIN” to return to the previous screen and try pasting the data from LBJ in again.'

				this.$confirm({
					title: 'Export to LBJ',
					text: msg,
					acceptText: 'OK',
					cancelText: 'Try Again',
					dialogMaxWidth: 650,
				}).then(y => {

				}).catch(n=>{
					// if user clicks cancel, go back to export_to_lbj_3
					this.export_to_lbj_3(export_rows)
				}).finally(f=>{})


			}
		},

		// added by Sunil
		export_to_lbj_csv() {
			let export_rows = []

			// PW: add header (8/15/2024)
			export_rows.push([
				'Volunteer Email',
				'LBJ Shift ID',
				'Shift Type',
				'Date',
				'Time',
				'Position',
				'VP Shift ID',
			])

			let year = date.format(new Date(), 'YYYY')

			for (let shift of this.shifts) {
				// note that this tool is only available to system admins; but include this next clause just to be safe
				// if user isn't a system admin, only show them shifts from their county
				if (!this.is_admin) {
					if (!this.user_info.matches_county(shift.county)) continue
					// if (shift.county != this.user_info.county) continue
				}

				// if county_filter is 'ALL...'
				if (this.show_all_counties) {
					// we show all counties except excluded_ALL_counties
					if (this.excluded_ALL_counties.includes(shift.county)) continue

				// else skip if county isn't in shift_counties
				} else if (!this.show_all_counties) {
					if (!this.shift_counties.includes(shift.county)) continue
				}

				// if date_filter is set, check date
				if (!this.date_filter.includes('ALL')) {
					if (this.date_filter.includes('UPCOMING')) {
						if (shift.date < this.today_date) continue
					} else {
						if (!this.date_filter.includes(shift.date)) continue
					}
				}

				// if priority_filter is set, check priority
				if (this.priority_filter[0] != 'ALL') {
					if (!this.priority_filter.includes(shift.priority)) continue
				}

				// ignore status filter

				// export rows that are either status=='filled' OR lbj_shift_database_id is non-empty and not 'deleted' (in which case we have to delete the shift in LBJ)
				if (!(shift.filled_for_lbj() || (!empty(shift.lbj_shift_database_id) && shift.lbj_shift_database_id != 'DELETED'))) {
					continue
				}

				// get the location record for the shift and the user record for the shift
				let location = this.locations.find(x=>x.vh_location_id==shift.vh_location_id)

				// get the email to use for the shift. first try to find the user record from the volunteer_email, then other_email, then lbj_email
				let user = this.users.find(x=>x.email==shift.volunteer_email)
				if (!user) user = this.users.find(x=>x.email==shift.other_email)
				if (!user) user = this.users.find(x=>x.email==shift.lbj_email)

				// now, if we didn't find a user, just use volunteer_email; otherwise use lbj_email if there or the main email otherwise
				let export_email = shift.volunteer_email
				if (user) {
					if (user.lbj_email) export_email = user.lbj_email
					else export_email = user.email
				}

				// push the values into the export_row demanded by the LBJ bulk import tool
				let export_row = []

				// volunteer-id email
				export_row.push(export_email)

				// location-id
				export_row.push(location.lbj_location_id)

				// shift-type
				export_row.push('poll')

				// shift-date	MM/DD/YYYY
				export_row.push(shift.date.replace(/-/gi, '/') + '/' + year)

				// shift-time	hhmm-hhmm
				export_row.push(`${shift.start_time}-${shift.end_time}`.replace(/^(\d:)/, '0$1').replace(/:/gi, ''))

				// place-status:	inside, unless "outside" is in shift location
				let place_status = 'inside'
				if (location.location.indexOf('outside') > -1 ) place_status = 'outside'
				export_row.push(place_status)

				// notes - add vh_shift_id here, so we can re-import after LBJ process runs
				export_row.push(shift.vh_shift_id)

				// Push row!!
				export_rows.push(export_row)
			}

			if (export_rows.length == 1) {
				this.$alert('No shifts would be exported.')
				return
			}

			let filename = `${this.site_data.vh_org_id}-lbj-bulk-upload-file-${date.format(new Date(), 'YYYY-MM-DD--HH-mm')}.csv`
			U.download_file(CSV.stringify(export_rows), filename)

			this.$alert('CSV file downloaded!')
		},
		
	}
}
</script>

<style lang="scss">
.k-admin-shifts-table-wrapper {
	border:1px solid #666;
	border-radius:10px;
	padding-top:8px;
}

.k-admin-shifts-table {
	th {
		white-space: nowrap;
	}
	td {
		font-size:12px!important;
		color:#000!important;
	}
}
</style>
