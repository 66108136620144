<template><div>
	<div class="d-flex align-center mb-4">
		<v-text-field
			v-model="search"
			prepend-inner-icon="fa fa-search" clearable clear-icon="fa fa-times-circle"
			label="Search" single-line hide-details outlined dense background-color="#fff" style="flex:0 1 350px"
		></v-text-field>
		<!-- <div>Select a county:</div> -->
		<div class="ml-3" style="width:250px"><v-autocomplete outlined dense hide-details v-model="county" label="County" :items="available_counties"></v-autocomplete></div>
		<div class="ml-3" style="width:250px"><v-select outlined dense hide-details v-model="date_option" label="" :items="date_options"></v-select></div>
		<v-spacer/>
		<v-btn class="ml-3" color="primary" @click="new_shift_clicked" style="letter-spacing:0;text-transform:none;"><v-icon small class="mr-2">fas fa-plus</v-icon> Add New Shift(s)</v-btn>
		<!-- <v-btn class="ml-3" fab small color="primary" @click="get_shifts"><v-icon>fas fa-sync-alt</v-icon></v-btn> -->
	</div>
	<div v-if="county"><div class="k-admin-shift-grid-wrapper elevation-3">
		<v-data-table dense
			:headers="headers"
			:items="rows"
			:custom-filter="table_search_filter"
			:search="search"
			:hide-default-footer="true"
			:items-per-page="-1"
			:fixed-header="true"
			:hide-default-header="true"
			:height="'calc(100vh - 100px)'"
			class="k-admin-shift-grid"
		>
			<template v-slot:header>
				<thead>
					<tr>
						<th v-for="(header, index) in headers">
							<div v-if="index==0">Location</div>
							<div v-if="index==1&&date_option=='ed'" class="text-center">ED<br>Pri.</div>
							<div v-if="(date_option!='ed'&&index>0)||index>1" class="ml-2">
								{{header.date_arr.date}} <v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" small icon color="primary" class="ml-1" style="margin-top:-2px" outlined @click="email_scheduled_volunteers(header)"><v-icon small>fas fa-envelope</v-icon></v-btn></template>Email scheduled volunteers</v-tooltip>
							</div>
						</th>
					</tr>
				</thead>
			</template>
			<template v-slot:item="{ item }"><tr>
				<td style="min-width:300px"><div style="margin-left:20px;text-indent:-20px;"><v-icon small class="mr-2">fas fa-location-dot</v-icon>{{item.location_record.location}}</div></td>
				<td v-if="date_option=='ed'" class="text-center px-0">{{item.ed_priority}}</td>
				<!-- one td per date_record -->
				<td v-for="(date_record, index) in item.row_date_arr" :key="index" :style="(item.row_date_arr.length==1)?'width:50%':'width:25%'"><div class="d-flex">
					<!-- within the td, one "block" per shift -->
					<div v-for="(shift, index) in date_record.shifts" class="k-admin-shift-grid-shift" :class="shift_class(shift)" @click.stop="volunteer_cell_clicked(shift)">
						<nobr><!--
						--><v-menu bottom left><template v-slot:activator="{on}"><v-icon v-on="on" class="k-admin-grid-shift-status-icon" @click="user_showing_shift_id=null;shift_volunteer_being_edited_id=null">{{status_icon(shift.status)}}</v-icon></template>
							<v-list dense>
								<v-list-item v-for="(sitem) in statuses" :key="sitem.value" @click="update_shift(shift, 'status', sitem.value)"><v-list-item-title><v-icon small color="#333" class="mr-2" v-visible="sitem.value==shift.status">fas fa-circle</v-icon>{{sitem.text}}</v-list-item-title></v-list-item>
							</v-list>
						</v-menu><!--
							--><span class="k-admin-shift-grid-shift-volunteer">{{shift_initials(shift)}}</span><!--
							-->{{shift.start_time}}–{{shift.end_time}}<!--
						--></nobr>
						<UserInfo v-if="user_showing_shift_id&&user_showing_shift_id==shift.vh_shift_id" :user="users.find(x=>x.email==shift.volunteer_email)" :show_edit_btn="true" @edit="volunteer_cell_clicked(shift,'edit')" @close="user_showing_shift_id=null" style="margin-left:-16px;margin-top:8px" />
						<ShiftUserEditor v-if="shift_volunteer_being_edited_id==shift.vh_shift_id" :shift="shift" :field="'volunteer_email'" @close="shift_volunteer_being_edited_id=null" @save="update_shift" style="margin-left:-220px; margin-top:10px;" />
					</div>
				</div></td>
			</tr></template>
		</v-data-table>
	</div></div>

	<AdminEditShifts v-if="shift_being_edited" :shift_being_edited="shift_being_edited" @close="shift_being_edited=null"></AdminEditShifts>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import UserInfo from '../users/UserInfo'
import AdminEditShifts from './AdminEditShifts'
import ShiftUserEditor from './ShiftUserEditor'

export default {
	components: { UserInfo, AdminEditShifts, ShiftUserEditor },
	props: {
		// req: { type: String, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		date_options: [
			{value:'all_ev', text:'All Early Voting'},
			{value:'upcoming_ev', text:'Upcoming Early Voting'},
			{value:'ed', text:'Election Day'},
		],
		date_option: 'upcoming_ev',
		// date_option: 'all_ev',
		headers: [],
		footer_options: {
			itemsPerPageOptions: [10,25,100,-1],
		},
		search: '',
		shift_being_edited: null,
		user_showing_shift_id: null,
		shift_volunteer_being_edited_id: null,
	}},
	computed: {
		...mapState(['user_info', 'users', 'shifts', 'locations', 'site_data', 'today_date']),
		...mapGetters(['is_admin', 'all_dates', 'all_dates_hotline', 'user_hash']),
		statuses() { return U.shift_status_select_items() },
		available_counties() {
			// admins can see any county; others can only see their counties
			if (this.is_admin) return this.site_data.counties
			return this.user_info.counties
		},
		rows() {
			if (this.locations.length == 0 || this.shifts.length == 0 || this.users.length == 0) return []

			let arr = []

			// get dates to show for each row
			let date_arr = []
			if (this.date_option == 'ed') {
				date_arr = [{date: this.site_data.election_date, shifts:[]}]
			} else {
				let all_dates = (this.county.toLowerCase() == 'hotline') ? this.all_dates_hotline : this.all_dates
				for (let d of all_dates) {
					if (d.value == this.site_data.election_date) continue
					if (this.date_option == 'upcoming_ev' && d.value < this.today_date) continue
					date_arr.push({date: d.value, shifts:[]})
				}
			}

			// go through all shifts
			for (let s of this.shifts) {
				// if user isn't a system admin, only show them people from their county
				if (!this.is_admin) {
					if (!this.user_info.matches_county(s.county)) continue
					// if (s.county != this.user_info.county) continue
				}

				// only choose shifts for the selected county and dates
				if (s.county != this.county) continue

				if (this.date_option == 'ed') {
					// election day only
					if (s.date != this.site_data.election_date) continue
				} else {
					// early voting only
					if (s.date == this.site_data.election_date) continue

					// also only include today or later
					if (this.date_option == 'upcoming_ev' && s.date < this.today_date) continue
				}

				// if this shift's location doesn't already have a row, add one
				let row = arr.find(x=>x.vh_location_id == s.vh_location_id)
				if (!row) {
					let lr = this.locations.find(x=>x.vh_location_id == s.vh_location_id)
					if (!lr) {
						console.log('bad location! ' + s.vh_location_id)
					}
					row = {
						vh_location_id: s.vh_location_id,
						location_record: lr,
						location_lc: lr.location,
						volunteer_lc: '',
						ed_priority: lr.ed_priority,
						row_date_arr: [],
					}
					// copy all date records from date_arr
					for (let da of date_arr) {
						row.row_date_arr.push({date: da.date, shifts:[]})
					}

					arr.push(row)
				}

				// push shift onto row's date array
				let rda = row.row_date_arr.find(x=>x.date==s.date)
				if (empty(rda)) {
					console.log('error', row)
					// PW: this broke initially when we added the "hotline" county
					// continue
				}
				rda.shifts.push(s)

				// add shift's volunteer's name to volunteer_lc
				if (s.volunteer_email) {
					let vol = this.user_hash[s.volunteer_email]
					if (vol) row.volunteer_lc += ' ' + vol.email + ' ' + vol.name_first.toLowerCase() + ' ' + vol.name_last.toLowerCase()
				}

				// and push onto date_arr shifts, for use in email fn below
				let da = date_arr.find(x=>x.date==s.date)
				da.shifts.push(s)
			}

			// sort shifts for each row by start_time
			for (let row of arr) {
				for (let rda of row.row_date_arr) {
					rda.shifts.sort((a,b)=>{
						if (a.start_time < b.start_time) return -1
						if (a.start_time > b.start_time) return 1
						return 0	// shouldn't happen
					})
				}
			}

			// sort locations
			arr.sort((a,b) => {
				// for ed, sort by ed_priority
				if (this.date_option == 'ed' && a.ed_priority != b.ed_priority) {
					return a.ed_priority - b.ed_priority
				}

				// else sort by default priority
				if (a.location_record.default_priority != b.location_record.default_priority) {
					return a.location_record.default_priority - b.location_record.default_priority
				}

				// easy natural sort algorithm that actually seems to work!
				// https://fuzzytolerance.info/blog/2019/07/19/The-better-way-to-do-natural-sort-in-JavaScript/
				return a.location_lc.localeCompare(b.location_lc, navigator.languages[0] || navigator.language, {numeric: true, ignorePunctuation: true})
			})

			// construct headers
			this.headers = [{ text: 'Location', align: 'left', sortable: true, value:'location_lc' }]
			if (this.date_option == 'ed') {
				this.headers.push({ text: 'ED Pri.', align: 'center', sortable: true, value:'ed_priority' })
			}
			for (let da of date_arr) {
				this.headers.push({ date_arr: da, align: 'left', sortable: false, value:'vh_location_id' })
			}

			return arr
		},
		county: {
			get() {
				if (this.is_admin) return this.$store.state.lst.default_county

				// edge case: if this isn't an admin and default_county isn't viewable by the user, choose the user's first county
				if (this.user_info.matches_county(this.$store.state.lst.default_county)) return this.$store.state.lst.default_county
				return this.user_info.counties[0]
				// // if user isn't a system admin, always show their county
				// if (!this.is_admin) return this.user_info.county
				// return this.$store.state.lst.default_county
			},
			set(val) { this.$store.commit('lst_set', ['default_county', val]) }
		},
	},
	watch: {
	},
	created() {
		this.get_shifts()
		if (this.today_date == this.site_data.election_date) this.date_option = 'ed'
	},
	mounted() {
	},
	methods: {
		get_shifts() {
			if (this.shifts.length == 0) this.$store.dispatch('get_shifts')
			if (this.users.length == 0) this.$store.dispatch('get_users')
			if (this.locations.length == 0) this.$store.dispatch('get_locations')
		},
		status_icon(status) { return U.shift_status_to_icon(status) },
		shift_class(shift) {
			if (this.shift_volunteer_being_edited_id == shift.vh_shift_id) {
				return 'k-admin-shift-grid-shift-being-edited'
			} else if (['not_open', 'open'].find(x=>x==shift.status)) {
				return 'k-admin-shift-grid-shift-open ' + U.shift_priority_to_color_light(shift.priority)
			} else {
				return 'k-admin-shift-grid-shift-filled ' + U.shift_priority_to_color_dark(shift.priority)
			}
		},
		shift_initials(shift) {
			if (!shift.volunteer_email) return '  '	// non-breaking spaces
			let user = this.users.find(x=>x.email==shift.volunteer_email)
			if (!user) return '??'
			return (user.name_first ? user.name_first[0] : '?') + (user.name_last ? user.name_last[0] : '?')
		},
		table_search_filter(value, search, item) {
			// value is the value of the column (we can ignore this); search is the search string (could be empty)
			// RETURN FALSE TO HIDE THE ITEM

			// if search is empty, always return true, so the row will SHOW
			if (empty(search)) return true

			search = search.toLowerCase()
			let re = new RegExp(search, 'i')

			// check _lc fields
			if (item.location_lc.search(re) > -1) return true
			if (item.volunteer_lc.search(re) > -1) return true

			// if we get to here return false
			return false
		},

		update_shift(shift, key, val) {
			this.shift_volunteer_being_edited_id = null

			let edited_shift = new Shift(shift)
			edited_shift[key] = val

			// if we're moving status to not_open or open, clear volunteer_email
			if (key == 'status' && (val == 'not_open' || val == 'open')) edited_shift.volunteer_email = ''

			// if we're setting volunteer_email...
			if (key == 'volunteer_email') {
				// if we're clearing the volunteer and status wasn't open or not_open, set to open
				if (val == '' && !(edited_shift.status == 'not_open' || edited_shift.status == 'open')) {
					edited_shift.status = 'open'

				// if we're setting the volunteer and status was open or not_open, set to filled
				} else if (val != '' && (edited_shift.status == 'not_open' || edited_shift.status == 'open')) {
					edited_shift.status = 'filled'
				}
			}

			if (U.check_for_shift_overlap(this.shifts, this.locations, edited_shift)) return

			this.$store.dispatch('save_shift', {shift: edited_shift}).then((result)=>{
				// this.$emit('cancel')
			}).catch((result)=>{
				// shouldn't happen
			})
		},

		new_shift_clicked() {
			this.shift_being_edited = new Shift({county:this.county})
		},

		volunteer_cell_clicked(shift, flag) {
			if (!shift.volunteer_email || flag == 'edit') {
				this.user_showing_shift_id = null
				this.shift_volunteer_being_edited_id = shift.vh_shift_id
			} else {
				this.user_showing_shift_id = shift.vh_shift_id
			}
		},

		email_scheduled_volunteers(header) {
			let date_arr = header.date_arr
			console.log(date_arr)

			// make sure we have some emails to send
			let has_emails = false
			for (let shift of date_arr.shifts) {
				if (shift.volunteer_email) {
					has_emails = true
					break
				}
			}
			if (!has_emails) {
				this.$alert('No volunteers have signed up for shifts on this date.')
				return
			}

			this.$prompt({
				title: 'Send Email to Volunteers for Shifts on ' + date_arr.date,
				text: '<div class="mb-2">Select the criteria for volunteers you would you like to send an email below, then click OPEN EMAIL APP to open your email application with a default message addressed to these volunteers (in the BCC line of the email). You can then edit the default message, if you wish, and send the email to the volunteers</div>',
				promptType: 'select',
				selectOptions: [{value:'all', text: 'All volunteers signed up for a shift on this date'}, {value:'unconfirmed', text: 'Volunteers who have NOT YET CONFIRMED'}, {value:'confirmed', text: 'Volunteers who HAVE CONFIRMED'}],
				initialValue: 'all',
				acceptText: 'Open Email APP',
				dialogMaxWidth: 600,
			}).then(group => {
				let emails = []
				for (let shift of date_arr.shifts) {
					if (shift.volunteer_email) {
						if (group == 'all' || (group == 'unconfirmed' && shift.status == 'filled') || (group == 'confirmed' && (shift.status == 'confirmed' || shift.status == 'checked_in'))) {
							if (!emails.includes(shift.volunteer_email)) emails.push(shift.volunteer_email)
						}
					}
				}

				if (emails.length == 0) {
					this.$alert('No volunteers match the criteria you set.').then(x=>{ this.email_scheduled_volunteers(header) })
					return
				}

				let subs = {
					date: date_arr.date,
					county: this.county,
					sender_name: this.user_info.name_first + ' ' + this.user_info.name_last,
					shifts_url: window.location.origin + '/vp/shifts'
				}

				if (group == 'unconfirmed') {
					subs.subject = 'Please CONFIRM '
					subs.confirm_verb = 'confirm'
				} else {
					subs.subject = 'Your '
					subs.confirm_verb = 're-confirm'
				}

				if (this.county == 'Hotline') {
					subs.shift_adj = 'hotline'
					subs.links = this.site_data.shift_grid_email_hotline_links
					subs.subject += 'upcoming Hotline shift(s) on '
				} else {
					subs.shift_adj = 'poll watcher'
					subs.links = this.site_data.shift_grid_email_watcher_links
					subs.subject += 'upcoming Poll Watcher shift on '
				}
				subs.subject += subs.date

				// Good morning/afternoon/evening
				let hour = new Date().getHours()
				if (hour < 12) subs.day_string = 'Good morning'
				else if (hour < 5) subs.day_string = 'Good afternoon'
				else subs.day_string = 'Good evening'

				let body_template = 
`$day_string!

I am reaching out to you because you have an upcoming $shift_adj shift(s) on $date. Please visit our scheduling website to $confirm_verb your shift(s):

$shifts_url

Thank you so much for your dedication to protecting democracy.

If you have not already, please review the following materials before your shift:

$links

Sincerely,

$sender_name
$county VoPro Team
`

				let body = sr(body_template, subs)
				console.log(emails, subs, body)
				body = body.replace(/\n/g, 'XXBREAKXX')
				body = encodeURI(body).replace(/XXBREAKXX/g, '%0D%0A')

				let href = sr('mailto:$1?from=$1&bcc=$2&subject=$3&body=$4', this.user_info.email, emails.join(','), subs.subject, body)
				console.log(href)
				$('body').append(sr('<a id="mailto_link" style="display:none;" href="$1">&nbsp;</a>', href))
				document.getElementById('mailto_link').click()
				setTimeout(x=>$('#mailto_link').remove(), 0)

			}).catch(n=>{console.log(n)}).finally(f=>{})
		},
	}
}
</script>

<style lang="scss">
.v-application {
	.k-admin-shift-grid-wrapper {
		border:1px solid #666;
		border-radius:10px;
		padding-top:8px;
	}

	.k-admin-shift-grid {
		border-radius:10px;
		// this makes positioning of the "popups" correct
		.v-data-table__wrapper {
			position:relative;
		}

		// this leaves space for the popups to appear for the bottom rows
		table {
			padding-bottom:150px;
		}

		th {
			white-space: nowrap;
			border-right:1px solid #ddd;
		}
		td {
			font-size:12px!important;
			color:#000!important;
			border-right:1px solid #ddd;
		}

		.k-admin-shift-grid-shift {
			// position:relative;
			// z-index:2;
			background-color:#ccc;
			margin:4px;
			padding:4px;
			border-radius:4px;
			text-align:center;
			border:2px solid transparent;
			cursor:pointer;
		}

		.k-admin-shift-grid-shift-being-edited {
			background-color:$v-brown-lighten-4;
			border-color:$v-brown-darken-2;
		}

		.k-admin-shift-grid-shift-open {
			color:#000;
			.k-admin-grid-shift-status-icon { color:#999!important; }
		}

		.k-admin-shift-grid-shift-filled {
			color:#fff;
			border-color:#000!important;
			.k-admin-grid-shift-status-icon { color:#fff!important; }
		}

		.k-admin-shift-grid-shift-volunteer {
			font-family:monospace;
			font-size:16px;
			line-height:14px;
			padding-right:6px;
			padding-left:8px;
			font-weight:bold;
		}
	}
}
</style>
