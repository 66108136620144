class Shift {
	constructor(data) {
		if (empty(data)) data = {}

		sdp(this, data, 'vh_shift_id', 0)
		sdp(this, data, 'lbj_shift_database_id', '')	// could be empty, a number, or 'DELETED'
		sdp(this, data, 'vh_org_id', vapp.$store.state.vh_org_id)
		sdp(this, data, 'county', '')
		sdp(this, data, 'vh_location_id', 0)
		sdp(this, data, 'date', '')
		sdp(this, data, 'start_time', '09:00')
		sdp(this, data, 'end_time', '19:00')
		// 1 is highest priority; 4 is lowest priority
		sdp(this, data, 'priority', 2)
		// but we initially allowed for 5 and 6; switch those to 4
		if (this.priority > 4) this.priority = 4

		sdp(this, data, 'admin_email', '')
		sdp(this, data, 'volunteer_email', '')
		sdp(this, data, 'status', 'not_open', ['not_open', 'open', 'filled', 'confirmed', 'checked_in', 'completed'])
	}

	filled_for_lbj() {
		return (this.status == 'filled' || this.status == 'confirmed' || this.status == 'checked_in' || this.status == 'completed')
	}
}
window.Shift = Shift

U.shift_priority_to_color = function(priority) {
	if (priority == 0) return ''
	if (priority == 1) return 'red lighten-3'
	if (priority == 2) return 'orange lighten-3'
	if (priority == 3) return 'yellow lighten-3'
	if (priority == 4) return 'green lighten-3'
}

U.shift_priority_to_color_light = function(priority) {
	if (priority == 0) return ''
	if (priority == 1) return 'red lighten-4'
	if (priority == 2) return 'orange lighten-4'
	if (priority == 3) return 'yellow lighten-4'
	if (priority == 4) return 'green lighten-4'
}

U.shift_priority_to_color_dark = function(priority) {
	if (priority == 0) return ''
	if (priority == 1) return 'red darken-2'
	if (priority == 2) return 'orange darken-2'
	if (priority == 3) return 'yellow darken-2'
	if (priority == 4) return 'green darken-2'
}

U.shift_priority_to_color_text = function(priority) {
	if (priority == 0) return ''
	if (priority == 1) return 'red--text text--darken-2'
	if (priority == 2) return 'orange--text text--darken-2'
	if (priority == 3) return 'yellow--text text--darken-2'
	if (priority == 4) return 'green--text text--darken-2'
}

U.shift_priority_select_items = function() {
	return [
		// { value: 0, text: '0 (unset)' },
		{ value: 1, text: '1 (red - highest)' },
		{ value: 2, text: '2 (orange)' },
		{ value: 3, text: '3 (yellow)' },
		{ value: 4, text: '4 (green)' },
	]
}

U.shift_status_description = function(status) {
	if (status == 'not_open') return 'Not Open'
	if (status == 'checked_in') return 'Checked In'
	return U.capitalize_word(status)
}

U.shift_status_select_items = function() {
	let arr = []
	for (let status of ['not_open', 'open', 'filled', 'confirmed', 'checked_in', 'completed']) {
		arr.push({
			value: status,
			text: U.shift_status_description(status)
		})
	}
	return arr
}

U.shift_status_to_icon = function(status) {
	if (status == 'not_open') return 'fas fa-circle-minus'
	if (status == 'open') return 'fas fa-circle'
	if (status == 'filled') return 'fas fa-circle-dot'
	// for confirmed, checked_in, completed return the same thing
	return 'fas fa-circle-check'
}

U.check_for_shift_overlap = function(shifts, locations, updated_shift) {
	// updated_shift.vh_location_id could be an array, if we're coming from AdminEditShifts; so if it's not an array, make it an array, then process once per location
	let vh_location_ids = updated_shift.vh_location_id
	if (!$.isArray(vh_location_ids)) vh_location_ids = [vh_location_ids]

	for (let vh_location_id of vh_location_ids) {
		let location_record = locations.find(x=>x.vh_location_id==vh_location_id)
		let location = (location_record) ? location_record.location : '???'
		let updated_shift_description = sr('$1 County: $2 $3-$4 / $5', updated_shift.county, updated_shift.date, updated_shift.start_time, updated_shift.end_time, location)

		// for every other shift...
		for (let os of shifts) {
			// skip updated_shift itself (if this isn't a new shift, it will be in the shifts list)
			if (updated_shift.vh_shift_id == os.vh_shift_id) continue

			// if the date/times overlap...
			if (os.date == updated_shift.date) {
				if ((os.start_time == updated_shift.start_time || os.end_time == updated_shift.end_time) || (os.start_time > updated_shift.start_time && os.start_time < updated_shift.end_time) || (os.end_time > updated_shift.start_time && os.end_time < updated_shift.end_time)) {

					// if the locations are identical, these are identical shifts, so don't allow
					if (vh_location_id == os.vh_location_id) {
						vapp.$alert(sr('The new shift ($1) overlaps with a shift that’s already been created.', updated_shift_description))
						return true
					}

					// if the volunteer_emails are identical, we're trying to sign the same person up for two overlapping shifts, so don't allow
					if (updated_shift.volunteer_email && (updated_shift.volunteer_email == os.volunteer_email)) {
						vapp.$alert(sr('The volunteer being assigned to this shift ($1) is already signed up for another shift at the same date/time.', updated_shift_description))
						return true
					}
				}
			}
		}
	}

	// if we get to here, return false, meaning that it's OK for new_shift to be saved
	return false
}
