<template><div class="k-page-holder">
	<PageTitle tab_id="shifts" />
	<div class="mt-3 k-my-shifts-table-wrapper">
		<div class="d-flex"><v-spacer/><div class="text-center green lighten-4 px-2" style="border-radius:6px;">The current date is <b>{{today_date}}</b></div><v-spacer/></div>
		<div v-if="my_shifts.length==0" class="mt-3">You are not signed up for any upcoming shifts.</div>
		<div v-if="my_shifts.length>0" class="mt-3">
			<v-data-table dense
				:headers="headers"
				:items="my_shift_rows"
				:must-sort="false"
				:hide-default-footer="true"
				:items-per-page="-1"
				class="k-my-shifts-table"
			>
				<template v-slot:item="{ item }"><tr>
					<td style="width:10px; white-space:nowrap" class="text-center">
						<v-tooltip bottom><template v-slot:activator="{on}"><span v-on="on" v-if="item.status=='filled'">
							<b>Scheduled</b>
							<v-btn :disabled="!shift_confirmable(item.shift)" x-small color="orange darken-3" :style="shift_confirmable(item.shift)?'color:#555!important':''" light class="ml-1" @click="change_shift_status(item.shift,'confirmed')"><b>Confirm</b></v-btn>
						</span></template><div class="text-center" v-html="confirm_btn_tooltip(item.shift)"></div></v-tooltip>
						<span v-if="['confirmed','checked_in','completed'].includes(item.status)" style="margin-right:50px">
							<v-icon small color="green darken-1">fas fa-circle-check</v-icon>
							<b class="green--text text--darken-1">CONFIRMED</b>
						</span>
						<!-- <span v-if="item.status=='confirmed'">
							<b>Confirmed</b>
							<v-btn x-small color="blue darken-2" dark class="ml-1" @click="change_shift_status(item.shift,'checked_in')">Check In</v-btn>
						</span>
						<span v-if="item.status=='checked_in'">
							<b>Checked In</b>
							<v-btn x-small color="deep-purple darken-1" dark class="ml-1" @click="change_shift_status(item.shift,'completed')">Mark Complete</v-btn>
						</span>
						<span v-if="item.status=='completed'">
							<b><v-icon small color="deep-purple darken-1">fas fa-check-circle</v-icon> Completed</b>
						</span> -->
						<v-tooltip right><template v-slot:activator="{on}"><v-btn v-if="item.status!='completed'" v-on="on" small icon color="red" class="ml-0" @click="change_shift_status(item.shift,'open')"><v-icon small>fas fa-times-circle</v-icon></v-btn></template>I can’t make this shift</v-tooltip>
					</td>
					<td><v-tooltip left><template v-slot:activator="{on}"><div v-on="on">{{item.county}}</div></template>Shift ID: {{item.vh_shift_id}}</v-tooltip></td>
					<td>{{item.location}}</td>
					<td class="text-center"><nobr v-html="item.date_display"></nobr></td>
				</tr></template>
			</v-data-table>
		</div>
	</div>

	<div v-if="!signing_up" class="k-new-shift-signup-wrapper text-center mt-5 mb-3"><v-btn large color="green darken-2" dark @click="signing_up=true"><v-icon class="mr-2">fas fa-plus</v-icon>Sign up for a new shift</v-btn></div>
	<MyShiftsSignup v-if="signing_up" @cancel="signing_up=false" />

	<PageSections tab_id="shifts" />
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import MyShiftsSignup from './MyShiftsSignup'
// note: there's no reason do use this mixin for now, since we can easily load all emails into memory. Leaving the mixin here for reference
import AutocompleteEmailMixin from './mixins/AutocompleteEmailMixin.js'
import PageTitle from '@/components/PageTitle'
import PageSections from '@/components/PageSections'

export default {
	components: { MyShiftsSignup, PageTitle, PageSections },
	mixins: [AutocompleteEmailMixin],
	props: {
		// req: { type: String, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		headers: [
			{ text: 'Status', align: 'center', sortable: false, value:'vh_shift_id' },
			{ text: 'County', align: 'left', sortable: false, value:'county' },
			{ text: 'Location', align: 'left', sortable: true, value:'location' },
			{ text: 'Date/Time', align: 'center', sortable: true, value:'date_sort' },
		],
		footer_options: {
			itemsPerPageOptions: [10,25,100,-1],
		},
		viewing_as: '',
		selecting_viewing_as: false,
		signing_up: false,
	}},
	computed: {
		...mapState(['my_shifts', 'user_info', 'users', 'shifts', 'locations', 'today_date', 'this_year', 'site_data']),
		...mapGetters(['is_admin']),
		election_date() { return this.$store.state.site_data.election_date },
		user() {
			if (!empty(this.viewing_as)) return this.users.find(x=>x.email==this.viewing_as)
			else return this.user_info
		},
		my_shift_rows() {
			let arr = []
			for (let s of this.my_shifts) {
				// TODO: hide already-completed shifts
				let date_sort = s.date + ' ' + s.start_time + ' ' + s.end_time
				let date_display
				if (s.date == this.election_date) date_display = sr('<b class="red darken-3 white--text" style="padding:0 4px;border-radius:4px">$1</b> ', s.date.replace(/^20\d+-/, ''))
				else date_display = sr('<b style="padding:0 4px;">$1</b> ', s.date.replace(/^20\d+-/, ''))
				if (s.start_time) date_display += '&nbsp;&nbsp;' + s.start_time
				if (s.end_time) date_display += '-' + s.end_time

				if (true) date_display += sr('<i class="pl-1 fas fa-circle-dot $1"></i>', U.shift_priority_to_color_text(s.priority))

				let location_record = this.locations.find(x=>x.vh_location_id==s.vh_location_id)
				let location = (!location_record) ? '???' : location_record.location

				let o = {
					vh_shift_id: s.vh_shift_id,
					priority: s.priority,
					county: s.county,
					location: location,
					date_sort: date_sort,
					date_display: date_display,
					status: s.status,

					shift: s,
				}

				arr.push(o)
			}

			// initial sort
			arr.sort((a,b) => {
				// by date/time
				if (a.date_sort < b.date_sort) return -1
				if (a.date_sort > b.date_sort) return 1

				// then by location (though this shouldn't matter, because you should never have two shifts at the same time)
				if (a.location_lc < b.location_lc) return -1
				if (a.location_lc > b.location_lc) return 1

				return 0
			})

			return arr
		},
		volunteer_emails() {
			let arr = []
			for (let u of this.users) {
				let o = {value: u.email}
				if (!empty(u.name_last)) {
					o.text = sr('$1 ($2, $3)', u.email, u.name_last, u.name_first)
				} else {
					o.text = u.email
				}
				arr.push(o)
			}
			return arr
		},
	},
	watch: {
		viewing_as() {
			if (this.viewing_as) this.selecting_viewing_as = false
		},
	},
	created() {
		// this.viewing_as = this.user_info.email
		// TEMP: show as lynfilm@gmail.com
		// this.viewing_as = 'lynfilm@gmail.com'
		// this.find_my_shifts(this.viewing_as)
	},
	mounted() {
		if (this.is_admin) this.get_shifts()
		if (this.locations.length == 0) this.$store.dispatch('get_locations')
	},
	methods: {
		get_shifts() {
			if (this.shifts.length == 0) this.$store.dispatch('get_shifts')
			if (this.users.length == 0) this.$store.dispatch('get_users')
		},

		go_to_route(route_name) {
			vapp.go_to_route(route_name)
		},

		shift_confirmable(shift) {
			// ED shifts are always confirmable
			if (shift.date == this.election_date) return true

			// Date constructor can take fullYear, month (0-11), and date (1-31)
			let darr = this.today_date.split('-')
			let today_date_int = U.day_of_year(new Date(this.this_year, darr[0]*1-1, darr[1]*1))

			let sda = shift.date.split('-')
			let shift_date_int = U.day_of_year(new Date(this.this_year, sda[0]*1-1, sda[1]*1))

			// Old version of last 4 lines that didn't respect the state setting of today_date
			// let today_date = new Date()
			// let sda = shift.date.split('-')
			// let shift_date = new Date(today_date.getFullYear(), sda[0]*1-1, sda[1]*1)
			// today_date = new Date(today_date.getFullYear(), today_date.getMonth(), today_date.getDate())

			// let shift_date_int = U.day_of_year(shift_date)
			// let today_date_int = U.day_of_year(today_date)

			let diff = shift_date_int - today_date_int

			// console.log(sr('$1 - $2 = $3', shift_date_int, today_date_int, diff))

			// PW 9/26/2022: update so that shift is confirmable 2, rather than 1, day before the date of the shift
			return (diff >= 0 && diff <= 2)
		},

		confirm_btn_tooltip(shift) {
			if (!this.shift_confirmable(shift)) return 'Please confirm that you can make this shift<br><b>two days before</b> (or the day before) the shift.'
			else return 'Please confirm that you can make this shift!'
		},

		change_shift_status(shift, new_status) {
			// we shouldn't get here in this case anymore
			if (new_status == 'confirmed' && !this.shift_confirmable(shift)) {
				this.$alert('Please confirm that you can make this shift no earlier <b>two days before</b> the shift.')
				return
			}

			let edited_shift = new Shift(shift)
			edited_shift.status = new_status

			// if we're moving to open, clear volunteer_email; otherwise make sure it's set to the user
			if (new_status == 'open') {
				edited_shift.volunteer_email = ''

				// when volunteer cancels, tell service to send a notification email
				edited_shift.shift_cleared_send_email = 'yes'
				
			} else {
				edited_shift.volunteer_email = this.user.email
			}

			this.$store.dispatch('save_shift', {shift: edited_shift}).then((result)=>{
				// this.$emit('cancel')
			}).catch((result)=>{
				// shouldn't happen
			})
		},

		find_my_shifts(email) {
			if (empty(email)) email = this.user_info.email
			email = email.toLowerCase()

			let arr = []
			for (let shift of this.shifts) {
				if (!shift.active) continue

				for (let group of shift.groups) {
					// if the user is the captain of this group...
					if (group.captain_email.toLowerCase() == email) {
						arr.push({
							shift: shift,
							group: group,
							dialpad_user: this.dialpad_users.find(x=>x.username==group.captain_dialpad_username),
						})
					}
					for (let operator of group.operators) {
						// if this is the operator...
						if (operator.operator_email.toLowerCase() == email) {
							arr.push({
								shift: shift,
								group: group,
								dialpad_user: this.dialpad_users.find(x=>x.username==operator.dialpad_username),
							})
						}
					}
				}
			}
			// TODO: reverse array
			this.$store.commit('set', ['my_shifts', arr])
		},

		change_viewing_as() {
			this.$prompt({
				title: 'View As...',
				text: 'Enter email to view as',
				// promptType: 'textarea',		// default is 'text'
				initialValue: this.user_info.email,
				acceptText: 'View As This Email',
			}).then(email => {
				this.viewing_as = email
				this.find_my_shifts(email)

			}).catch(n=>{console.log(n)}).finally(f=>{});

		},
	}
}
</script>

<style lang="scss">
.k-shifts-wrapper {
	font-size:20px;
	margin:12px 0;
}

.k-my-shifts-table-wrapper {
	border:1px solid #999;
	padding:10px;
	border-radius:8px;
}

.k-my-shifts-table {
	th {
		white-space: nowrap;
	}
	td {
		font-size:14px!important;
	}
}
</style>
